

















import { Component, Prop } from 'vue-property-decorator';
import lottie from 'vue-lottie';
import * as animationData from '@/assets/loading-icon-data.json';
import BaseComponent from '@/components/shared/BaseComponent.vue';

@Component({
  name: 'loading',
  components: {
    lottie
  }
})
export default class Loading extends BaseComponent {
  @Prop({ default: 2 })
  public animationSpeedMultiplier!: number;
  @Prop({ default: 50 })
  public height!: number;
  @Prop({ default: 50 })
  public width!: number;
  @Prop({ default: '' })
  public text!: string;
  @Prop({ default: '1rem' })
  public fontSize!: string;

  private animate: any;
  private animationSpeed: number = 500;
  private animationData: object = animationData;

  private get gridColumnTemplateCss(): string {
    return `grid-template-columns: ${this.width}px auto;`;
  }

  private get defaultOptions(): object {
    const data: any = animationData;
    return {
      animationData: data.default
    };
  }

  public handleAnimation(anim: any) {
    this.animate = anim;
    this.animate.setSpeed(this.animationSpeedMultiplier);
  }

}
