












































// Libraries
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
// View Models
import { ITowerStage } from '@/view-models/stages/tower-view-models';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import towerStore from '@/store/towerStore';
import appStore from '@/store/appStore';
import userStore from '@/store/userStore';
// Components
// Services
// Stores
@Component({
  name: 'tower-stage-table-row'
})
export default class TowerStageTableRow extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public stage!: ITowerStage | any;
  @Prop({ required: true })
  public index!: number;
  @Prop({ required: true })
  public toggleAll!: boolean;
  // VUEX
  // Properties
   public isCollapsed: boolean = true;
  // Fields
  // Getters
  public get hasAlerts(): boolean {
    return (this.stage?.alerts?.length ?? 0) > 0;
  }
  public get hasEquipment(): boolean {
    return (this.stage?.equipment?.length ?? 0) > 0;
  }
  public get alertsStatus(): string | TranslateResult {
    return this.hasAlerts
        ? this.$t('assets.tower.stages.alertsStatusWarning', { num: this.stage.alerts.length }).toString()
        : this.$t('assets.tower.stages.alertsStatusOk');
  }
  public get equipmentStatus(): string | TranslateResult {
    return this.hasEquipment
        ? this.$t('assets.tower.stages.hasEquipment', { num: this.stage.equipment.length }).toString()
        : this.$t('assets.tower.stages.hasNoEquipment');
  }
  public get isEven(): boolean {
    return this.index == null ? false : ((this.index + 1) % 2 === 0);
  }
  public get stageName(): string | TranslateResult {
    return this.$t('assets.tower.stages.nameTitle', { num: this.stage.order });
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public toggleExpander(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  // Watchers
  @Watch('toggleAll')
  public toggleAllStages() {
    if (this.hasAlerts) {
      this.isCollapsed = this.toggleAll;
    }
  }

  public correspondingAlert(alertKey: string) {
    if(!towerStore.currentTower) {
    towerStore.loadTower(appStore.assetKey);
    }
    if (!towerStore.preferredMeasurementSystem) {
         towerStore.getUomOptions(userStore.authUser.activeCustomerKey);
      }
    towerStore.setStage(alertKey);
     this.$router.push(`/${alertKey}`);
  }
  // Emitters
}
