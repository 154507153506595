





















































































// Libraries
import { Component } from 'vue-property-decorator';
// View Models
import { ITower, ITowerValidation, UnixEpochTime } from '@/view-models';
import { IDashboardTimeRangeSetting } from '@/view-models';
// Components
import ChartWidget from '@/components/assets/tower/widgets/ChartWidget.vue';
import WidgetContainer from '@/components/assets/tower/widgets/WidgetContainer.vue';
import TowerEditTimeSettings from '@/components/assets/tower/TowerEditTimeSettings.vue';
import SlideOutPanel from '@/components/assets/tower/SlideOutPanel.vue';
import TowerEditGlobalSettings from '@/components/assets/tower/TowerEditGlobalSettings.vue';
import TowerModal from '@/components/common/TowerModal.vue';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import { MeasurementSystemsEnum } from '@/enums';
import TowerViewMixin from './components/mixins/TowerViewMixin';
import towerStore from './store/towerStore';
import appStore from '@/store/appStore';
import userStore from '@/store/userStore';
// Services
@Component({
    name: 'validations',
    components: {
      TowerEditGlobalSettings,
      TowerEditTimeSettings,
      SelectDropdown,
      ChartWidget,
      WidgetContainer,
      SlideOutPanel,
      TowerModal
    }
})
export default class Validations extends TowerViewMixin {
  // VUE.JS Props
  // Properties
  public currentMeasurementSystemKey: string = 'cbd32687-763d-47f3-b9f4-b504b052d491';
  public indicator: ITowerValidation | any = null;
  public loadingData: boolean = false;
  public showTimeControls: boolean = false;
  public showChartControls: boolean = false;
  public showTowerGraphic: boolean = false;
  public isPanelOpen: boolean = false;
  private initialValidationTimeSetting: IDashboardTimeRangeSetting| any = null;
  private initialValidationTimeSelection: Array<UnixEpochTime> = [];
  // VUEX
  public get tower(): ITower {
    return towerStore.currentTower;
  }
  public get towerTitle(): string {
    return `${this.tower?.name ?? ''}`;
  }
  public get towerGraphic(): string | undefined {
    return this.tower?.imageSignedUrl;
  }
  // Fields
  // Getters
  public get currentValidations(): Array<ITowerValidation> {
    return towerStore.currentValidationsWidgetData;
  }
  public get currentTimeRangeSetting(): IDashboardTimeRangeSetting {
    return towerStore.currentTimeRange;
  }
  public set currentTimeRangeSetting(value: IDashboardTimeRangeSetting) {
    towerStore.setTimeRange(value);
  }
  public get timeRangeCacheKey(): string {
    return `${this.tower?.key ?? ''}`;
  }
  public get validationTitle(): string {
    return this.indicator.displayValue;
  }
  private get currentTimeSelection(): Array<UnixEpochTime> {
    return towerStore.currentTimeSelections;
  }
  public get panelTitle(): string {
    if (this.showTimeControls) {
      return 'Time Settings';
    } else if (this.showChartControls) {
      return 'Chart Settings';
    } else if (this.showTowerGraphic) {
      return this.tower?.name;
    } else return '';
  }
  public get panelIcon(): string {
     if (this.showTimeControls) {
      return 'mdi mdi-clock';
    } else if (this.showChartControls) {
      return 'mdi mdi-chart-multiple';
    } else if (this.showTowerGraphic) {
       return 'mdi mdi-file-image-outline';
    } else return '';
  }

  public get preferredMeasurementSystem() {
    if(towerStore.preferredMeasurementSystem) {
      this.currentMeasurementSystemKey = this.preferredMeasurementSystem;
    }
    return towerStore.preferredMeasurementSystem;
  }

  public beforeCreate() {
    towerStore.loadTower(appStore.assetKey).then(() => {
      towerStore.loadTowerValidations();
      towerStore.getUomOptions(userStore?.authUser?.activeCustomerKey);
    });
  }
  public mounted() {
    this.initialValidationTimeSetting = Object.assign(this.currentTimeRangeSetting, {});
    this.initialValidationTimeSelection = Object.assign(this.currentTimeSelection, {});
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public togglePanel(toShow: string): void {
    switch (toShow) {
      case 'image':
        this.showTimeControls = false;
        this.showChartControls = false;
        this.showTowerGraphic = true;
        this.isPanelOpen = !this.isPanelOpen;
        break;
      case 'time':
        this.showChartControls = false;
        this.showTimeControls = true;
        this.showTowerGraphic = false;
        this.isPanelOpen = !this.isPanelOpen;
        break;
      case 'chart':
        this.showTimeControls = false;
        this.showChartControls = true;
        this.showTowerGraphic = false;
        this.isPanelOpen = !this.isPanelOpen;
        break;
      default:
        this.showTimeControls = false;
        this.showChartControls = false;
        this.showTowerGraphic = false;
        this.isPanelOpen = !this.isPanelOpen;
        towerStore.setTimeRange(this.initialValidationTimeSetting);
        towerStore.setCurrentTimeSelections(this.initialValidationTimeSelection);
    }
  }
  public updateWidgets(action: string) {
    if (action === 'update') {
      this.updateData();
    } else {
      this.togglePanel('none');
    }
  }
  public async updateData() {
    this.loadingData = true;
    this.isPanelOpen = !this.isPanelOpen;
    await towerStore.loadTowerValidations(this.currentMeasurementSystemKey);
    this.loadingData = false;
    this.initialValidationTimeSetting = Object.assign(this.currentTimeRangeSetting, {});
    this.initialValidationTimeSelection = Object.assign(this.currentTimeSelection, {});
  }
  public selectedWidgetsTimeChanged(action: string) {
    if (action === 'cancel') {
      this.showTimeControls = false;
      this.isPanelOpen = !this.isPanelOpen;
    } else if (action === 'update') {
      this.updateData();
    }
  }
  private setCurrentMeasurementSystemKey(key: string) {
    this.currentMeasurementSystemKey = key;
  }
  public onTowerValidationMeasurementSystemChange(option: MeasurementSystemsEnum) {
  const fromUomName = this.updatedUomOptions?.filter((uom: any) => uom.value === this.currentMeasurementSystemKey)[0].text
        .split(' ')[0];
    const toUomName = this.updatedUomOptions.filter((uom: any) => uom.value === option)[0].text;
    this.toastMessage(this.$t('dashboard.toast.threshold.message',
        { fromUomName: `${fromUomName}`, toUomName: `${toUomName}` }).toString(),
        this.$t('dashboard.toast.threshold.header').toString());
     this.setCurrentMeasurementSystemKey(option);
    this.updateData();
    this.isPanelOpen = false;
  }
    // Event Methods
  public menuActionSelected() {}
  public toggleZoomIcon() {}
    // Watchers
    // Emitters
}
