import {
  ChartTypesEnum,
  EquipmentIndicatorDisplayValuesEnum,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentNameEnum,
  TowerEquipmentTypeEnum,
  TowerIndicatorDisplayValuesEnum,
  TowerInputTypesEnum,
  TowerThresholdRangesEnum,
  TowerUnitOfMeasurementsEnum,
  TowerValidationsDisplayValuesEnum,
  UnitsOfMeasure,
  WidgetTypes,
} from '@/enums';
import { WidgetDataPoint } from '../widgets';

// Interface for TowerView Stages
export interface ITowerStage {
  alerts?: Array<ITowerAlert>;
  equipment: Array<ITowerStageEquipment>;
  input: ITowerStageInput;
  stageKey: string;
  order: number;
}
// Interface for TowerView Stage Equipment
export interface ITowerStageEquipment {
  inWarningLevel?: boolean;
  indicators: Array<IEquipmentIndicatorRequest | IEquipmentIndicator>;
  equipmentKey: string;
  name?: TowerEquipmentNameEnum;
  type: TowerEquipmentTypeEnum;
}
export interface ITowerStageInput {
  inWarningLevel?: boolean;
  indicators: Array<IStageInputIndicatorRequest | IStageInputIndicator>;
}

// Interface for TowerView Alerts
export interface ITowerAlert {
  displayValue: string;
  equipmentName: TowerEquipmentNameEnum;
  equipmentType: TowerEquipmentTypeEnum;
  formattedDisplayValue?: string;
  value: number;
}

export interface IEquipmentIndicatorRequest {
  displayValue: EquipmentIndicatorDisplayValuesEnum;
  equipmentName?: any;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentKey: string;
  key?: string;
  type: TowerInputTypesEnum;
  data?: Array<WidgetDataPoint>;
}
export interface IEquipmentIndicatorResponse extends IEquipmentIndicatorRequest {
  data: Array<WidgetDataPoint>;
}

export interface IStageInputIndicatorRequest {
  displayValue: StageInputIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  type: TowerInputTypesEnum;
  key?: string;
}
export interface IStageInputIndicatorResponse extends IStageInputIndicatorRequest {
  data: Array<WidgetDataPoint>;
}

// Interface for TowerView Equipment Indicators
export interface IEquipmentIndicator extends IEquipmentIndicatorResponse{
  chartSettings: IChartSettings;
  equipmentKey: string;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  unitOfMeasurement: string;
  toShow: string;
  data: Array<WidgetDataPoint>;
}

export interface IChartSettings {
  axis: string;
  chartType: ChartTypesEnum;
  isFullScreen: boolean;
  showTitleAtBottom?: boolean;
  sortOrder: number;
  widgetType: WidgetTypes;
  scale: { min: any, max: any };
}

// Interface ofr TowerView Stage Input Indicators
export interface IStageInputIndicator extends IStageInputIndicatorResponse{
  chartSettings: IChartSettings;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  unitOfMeasurement: string;
  toShow: string;
}

// Interface for a single Tower Threshold
export interface ITowerThreshold {
  alertType: TowerThresholdRangesEnum;
  color: string;
  maxValue: number;
  minValue: number;
  thresholdName: string;
  thresholdType: string;
}

export class StageInputIndicator implements IStageInputIndicator {
  public chartSettings: IChartSettings| any;
  public data: Array<WidgetDataPoint> = [];
  public displayValue: StageInputIndicatorDisplayValuesEnum | any;
  public equipmentType = TowerEquipmentTypeEnum.None;
  public inWarningLevel: boolean = false;
  public indicatorKey: string = '';
  public key: string;
  public thresholds: Array<ITowerThreshold> = [];
  public type: TowerInputTypesEnum | any;
  public unitOfMeasurement: string = '';
  public toShow: string = '';
  public stageOrder?: number;

  constructor(input: IStageInputIndicatorResponse) {
    Object.assign(this, input);
    this.key = input.displayValue;
  }
}

export interface ITowerValidation {
  chartSettings: IChartSettings;
  displayValue: string;
  equipmentType: TowerEquipmentTypeEnum;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: TowerValidationsDisplayValuesEnum
  }>;
  validationKey: string;
  type: TowerInputTypesEnum;
  unitOfMeasurement: string;
  toShow: string;
}

export class StageInputIndicatorRequest implements IStageInputIndicatorRequest {
  public displayValue: StageInputIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public type: TowerInputTypesEnum;

  constructor(input: StageInputIndicatorDisplayValuesEnum) {
    this.displayValue = input;
    this.equipmentType = TowerEquipmentTypeEnum.None;
    this.type = TowerInputTypesEnum.TowerStageInput;
  }
}

// Interface for TowerView Historical Alert data
export interface ITowerHistoricalAlert {
  towerKey : string;
  stageKey: string;
  equipmentKey: string;
  equipmentName: string;
  equipmentType: TowerEquipmentTypeEnum;
  displayName: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  displayValue: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  alertTimestamp: string;
  value: number;
  thresholdValue: number;
  status: string;
  clearedTimestamp: string;
  createdAt: string;
  chartSettings: IChartSettings;
  uom?: any;
  unitOfMeasurement: string;
  data?: Array<number[]>;
  type: TowerInputTypesEnum;
}
// Interface for a single Tower Threshold
export interface ITowerThreshold {
  alertType: TowerThresholdRangesEnum;
  color: string;
  maxValue: number;
  minValue: number;
  thresholdName: string;
  thresholdType: string;
}

// Interface for TowerView Towers
export interface ITower {
  alerts: number;
  createdAt?: string;
  customerKey: string;
  assetGroupingKey?: string;
  customerSiteKey: string;
  imageSignedUrl?: string;
  key: string;
  lastModifiedAt?: string;
  lastTowerViewCalculation?: string;
  location?: ILocationViewModel;
  name: string;
  orgKey?: string;
  sortStagesAscending: boolean;
  stages: Array<ITowerStage>;
  validations?: Array<ITowerValidation>;
  indicators?: Array<ITowerIndicator>
}

// Interface for TowerView Tower Level Indicators
export interface ITowerIndicator {
  chartSettings: IChartSettings;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: string;
  }>;
  displayValue: TowerIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentName?: TowerEquipmentNameEnum;
  key: string;
  type: TowerInputTypesEnum;
  unitOfMeasurement: string;
}

export interface ILocationViewModel {
  lat: number;
  lng: number;
};

export interface IReportChartSettings {
  is3D: boolean;
  showGridLines: boolean;
  leftUnitOfMeasure: UnitsOfMeasure;
  rightUnitOfMeasure: UnitsOfMeasure;
  syncAxisLimits: boolean;
}

export class EquipmentIndicator implements IEquipmentIndicator {
  public chartSettings: IChartSettings| any;
  public data: Array<WidgetDataPoint> = [];
  public displayValue: EquipmentIndicatorDisplayValuesEnum| any;
  public equipmentName? = '';
  public equipmentType: TowerEquipmentTypeEnum| any;
  public inWarningLevel: boolean = false;
  public indicatorKey: string = '';
  public equipmentKey: string = '';
  public key: string;
  public thresholds: Array<ITowerThreshold> = [];
  public type: TowerInputTypesEnum| any;
  public unitOfMeasurement: string = '';
  public toShow: string| any;
  public stageOrder?: number;

  constructor(input: IEquipmentIndicatorResponse) {
    Object.assign(this, input);
    this.key = `${input?.equipmentKey}:${input.displayValue}`;
  }
}

export class EquipmentIndicatorRequest implements IEquipmentIndicatorRequest {
  public displayValue: EquipmentIndicatorDisplayValuesEnum| any;
  public equipmentName?: TowerEquipmentNameEnum| any;
  public equipmentType: TowerEquipmentTypeEnum| any;
  public equipmentKey: string  = '';
  public key?: string;
  public type: TowerInputTypesEnum| any;

  constructor(input: IEquipmentIndicatorRequest) {
    Object.assign(this, input);
  }
}

export interface IObjectPropertyUpdate {
  validationKey?: string;
  indicatorKey?: string;
  equipmentKey?: string;
  equipmentType: string;
  path: string;
  value: any;
}

// Interface for tower view local storage
export interface ITowerStageStorageModel {
  value: string;
  timestamp: number;
  date?: any;
}

export class TowerIndicator implements ITowerIndicator {
  public chartSettings: IChartSettings | any;
  public inputs: Array<{
    data: Array<WidgetDataPoint>,
    dataSetName: TowerIndicatorDisplayValuesEnum
  }> = [];
  public displayValue: TowerIndicatorDisplayValuesEnum | any;
  public equipmentType: TowerEquipmentTypeEnum | any;
  public key: string;
  public type: TowerInputTypesEnum | any;
  public unitOfMeasurement: string = '';

  constructor(input : any, towerKey : any) {
    this.key = `${towerKey}:${input.displayValue}`;
    Object.assign(this, input);
  }
}

export class TowerValidation implements ITowerValidation {
  public chartSettings: IChartSettings | any;
  public displayValue: string = '';
  public inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: TowerValidationsDisplayValuesEnum
  }> = [];
  public equipmentType: TowerEquipmentTypeEnum| any;
  public validationKey: string = '';
  public type: TowerInputTypesEnum| any;
  public unitOfMeasurement: string = '';
  public toShow: string = '';

  constructor(input: ITowerValidation) {
    Object.assign(this, input);
  }
}

export class TowerThreshold implements ITowerThreshold {
  public alertType: TowerThresholdRangesEnum;
  public color: string;
  public maxValue: number;
  public minValue: number;
  public thresholdName: string;
  public thresholdType: string;

  constructor(alertType: TowerThresholdRangesEnum, color: string, maxValue: number, minValue: number, thresholdName: string, thresholdType: string) {
    this.alertType = alertType;
    this.color = color;
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.thresholdName = thresholdName;
    this.thresholdType = thresholdType;
  }
}

export type IWidgetDataTypes = any; // TODO refactor to incorporate the various types from the line above