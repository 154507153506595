import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales: IRequireContext = (require as any).context('../static/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, any> = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

interface IRequireContext {
  (key: string): any;
  keys: () => string[];
}
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
});

export function getI18N() {
  return i18n;
}

export default i18n;
