import {
    Module,
    VuexModule,
    getModule,
    Mutation,
  } from 'vuex-module-decorators';
import store from '@/store';
import { IAppStore } from '@/store/types/app';
@Module({
    namespaced: true,
    name: 'appStore',
    store,
    dynamic: true,
})
export class AppStore extends VuexModule implements IAppStore {
    // State
    public isLoading: boolean = true;
    public isAppInitialized: boolean = false;
    public assetKey: string  = '';
    public isStandAlone = false;
    public currentChildApp = '';
    // Getters
    public get getIsLoading(): boolean {
      return this.isLoading;
    }
    public get getIsAppInitialized(): boolean {
      return this.isAppInitialized;
    }
    // Mutations
    @Mutation
    public updateIsLoading(loading: boolean): void {
      this.isLoading = loading;
    }
    @Mutation
    public setIsAppInitialized(value: boolean): void {
      this.isAppInitialized = this.isAppInitialized || value;
    }
    @Mutation
    public setAssetKey(value: string): void {
      this.assetKey = value;
    }
    @Mutation
    public setIsStandAlone(value: boolean): void {
      this.isStandAlone = value;
    }
    @Mutation
    public setCurrentChildApp(value: string): void {
      this.currentChildApp = value;
    }
}
let module: AppStore = getModule(AppStore, store);
export const setAppModule = (newModule: AppStore) => (module = newModule);
export default module;
