import moment from 'moment';

export enum SortDirection {
  Ascending,
  Descending
}

function nullComparer<T>(a: T, b: T) {
  if (b == null && a == null) {
    return 0;
  }
  if (a == null && b != null) {
    return -1;
  }
  if (a != null && b == null) {
    return 1;
  }

  return null; // neither are null, so cannot compare
}

function keepDirection(dir: SortDirection, ascendingValue: any) {
  return dir === SortDirection.Ascending && ascendingValue !== 0 ? ascendingValue : (ascendingValue * -1);
}

function alphabeticSorter(): (a: string, b: string) => number;
function alphabeticSorter<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorter<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Ascending, selector);
}

function alphabeticSorterDesc(): (a: string, b: string) => number;
function alphabeticSorterDesc<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorterDesc<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Descending, selector);
}

function alphabeticSorterImpl<T>(dir: SortDirection, selector?: (o: T) => string, locale: string = 'en-US') {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB));
      const ascendingValue = res != null ? res : sA.localeCompare(sB, locale, { sensitivity: 'base', numeric: true });
      return keepDirection(dir, ascendingValue);
    };
  }

  return (a: string, b: string) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a.localeCompare(b, locale, { sensitivity: 'base', numeric: true });
    return keepDirection(dir, ascendingValue);
  };
}

function numericSorter(): (a: number, b: number) => number;
function numericSorter<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorter<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Ascending, selector);
}

function numericSorterDesc(): (a: number, b: number) => number;
function numericSorterDesc<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorterDesc<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Descending, selector);
}

function numericSorterImpl<T>(dir: SortDirection, selector?: (o: T) => number) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB));
      const ascendingValue = res != null ? res : sA - sB;
      return keepDirection(dir, ascendingValue);
    };
  }
  return (a: number, b: number) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a - b;
    return keepDirection(dir, ascendingValue);
  };
}

function dateSorter(): (a: Date | string, b: Date | string) => number;
function dateSorter<T>(selector: (o: T) => Date | string): (a: T, b: T) => number;
function dateSorter<T>(selector?: (o: T) => Date | string) {
  return dateSorterImpl(SortDirection.Ascending, selector);
}

function dateSorterDesc(): (a: Date | string, b: Date | string) => number;
function dateSorterDesc<T>(selector: (o: T) => Date | string): (a: T, b: T) => number;
function dateSorterDesc<T>(selector?: (o: T) => Date | string) {
  return dateSorterImpl(SortDirection.Descending, selector);
}

function dateSorterImpl<T>(dir: SortDirection, selector?: (o: T) => Date | string) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = nullComparer(sA, sB);
      if (res != null) {
        return keepDirection(dir, res);
      }

      const anchor = moment(sA);
      const ascendingValue = anchor.isSame(sB) ? 0 : anchor.isAfter(sB) ? -1 : 1;
      return keepDirection(dir, ascendingValue);
    };
  }
  return (a: Date | string, b: Date | string) => {
    const res = nullComparer(a, b);
    if (res != null) {
      return res;
    }
    const anchor = moment(a);
    const ascendingValue = anchor.isSame(b) ? 0 : anchor.isAfter(b) ? -1 : 1;
    return keepDirection(dir, ascendingValue);
  };
}

export {
  alphabeticSorter,
  alphabeticSorterDesc,
  numericSorter,
  numericSorterDesc,
  dateSorter,
  dateSorterDesc
};

// export function distinctT[](collection: T[]): T[] {
//   return collection == null ? collection : Array.from(new Set(collection));
// }

// export function cloneT[](array: T[] | any): T[] {
//   return array != null ? array.slice(0) : null;
// }

export function rangeArray(start: number, end: number, step: number = 1) {
  const range = [];
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
}
