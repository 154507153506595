import { IEntityViewModel } from '@/view-models/global-view-model';
import { IDateRange, IDateRangeObjects } from '@/view-models/report-time-model';
import { ITimeOfUseViewModel, TimeOfUseViewModel } from '@/view-models/time-of-use-view-models';
import { IReportChartSettings } from '@/view-models';
import {
  ReportRangeType,
  WidgetTypes,
  WcagColors,
  ReportAxis,
  SeriesTypes,
  UnitsOfMeasure,
  TotalBy,
  DashboardTimeRangesEnum,
  WidgetSizes
} from '@/enums';
import { ReportTimeHelper } from '@/shared/time-helper-methods';
import { alphabeticSorter } from '@/shared/array-utils';
import { newGuid } from '@/shared/string-utils';

export interface IDashboardTimeRangeSetting {
  type: DashboardTimeRangesEnum;
  range?: IDateRangeObjects;
  changeByUser?: boolean;
}

export interface IAssetDashboardViewModel {
  key: string;
  checksum: string;
  name: string;
  canEdit: boolean;
  createdOn?: string;
  modifiedOn?: string;
  widgets: Array<IWidgetViewModel>;
  gridColumnSize: number;
}

export interface IAvailableWidgetInputViewModel {
  parentKey?: string;
  key?: string;
  name?: string;
  availableInputs?: Array<IWidgetInputViewModel>;
}

export interface IWidgetData {
  widgetKey: string;
  dashboardKey: string;
  inputName?: string;
  data: Array<number[]>;
}

export interface IWidgetInputEntriesViewModel {
  key: string;
  data: Array<number[]>;
}

export interface IWidgetDataViewModel {
  data: any;
  inputName?: string;
  key?: string;
}
export interface IInputViewModel {
  inputId: number;
  interval: number;
  name: string;
  isNormalizable: boolean;
  seriesPerSchedule: boolean;
  timesOfUse: Array<ITimeOfUseViewModel>;
  unitDisplay: string;
}
export class InputViewModel implements IInputViewModel {
  public inputId: number = 0;
  public interval: number = 0;
  public name: string = '';
  public isNormalizable: boolean = false;
  public seriesPerSchedule: boolean = false;
  public timesOfUse: Array<ITimeOfUseViewModel> = [];
  public unitDisplay: string = '';

  constructor(input?: IInputViewModel) {
    if (input != null) {
      Object.assign({}, this);
      this.timesOfUse = input.timesOfUse.map((t: ITimeOfUseViewModel) => new TimeOfUseViewModel(t));
    }
  }

  public static getInputPlaceholder(): IInputViewModel {
    const inputPlaceholderId: number = 0; // This synchronizes the backend and frontend.
    const placeholderInput: InputViewModel = new InputViewModel();
    placeholderInput.inputId = inputPlaceholderId;
    placeholderInput.timesOfUse = [];
    placeholderInput.name = 'report.inputPlaceholder';
    placeholderInput.interval = 0;
    placeholderInput.unitDisplay = '';
    return placeholderInput;
  }
}

export interface IWidgetViewModel {
  key: string;
  name: string;
  position: number; // Order in array of widgets
  checksum?: string;
  dateRange?: IDateRange;
  is3D?: boolean;
  showGridLines: boolean;
  isSyncAxisLimits: boolean;
  isNormalized: boolean; // Is this needed??
  // seriesPerSchedule?: boolean;
  // refreshFrequency?: number;
  reportRangeType?: ReportRangeType;
  thresholds?: Array<IThresholdViewModel>;
  totalBy: TotalBy;
  minimum: number;
  maximum: number;
  inputs: Array<IWidgetInputViewModel>;
  type: WidgetTypes;
  size?: WidgetSizes;
  leftUnitOfMeasure: UnitsOfMeasure;
  rightUnitOfMeasure: UnitsOfMeasure;
  inputSampleSize: number;
  useDataSampling: boolean;
  readonly uniqueDataKey?: string;
  imagesSource?(): string;
  isDirty?(): boolean;
  resetChecksum?(): void;
  isValid?(): boolean;
  selectedTime?: Date
}

export interface IWidget extends IEntityViewModel {
  checksum: string;
  chartSettings: IReportChartSettings;
  reportInputs: Array<IWidgetInputViewModel>;
  pendingSnapshot: IWidgetSnapshot;
  // timeOptions: IWidgetTimeOptions;
  isActive: boolean;
  isTemplate: boolean;
  canEdit: boolean;
  isNormalized: boolean;
  drillDownRequested: boolean;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
}
// export interface IWidgetViewModel extends IWidget {
//   readonly validInputs: IWidgetInputViewModel[];
//   readonly placeholderInputs: IWidgetInputViewModel[];
//   prepareForNewSave(reportName: string): void;
//   determineRange(existingRange: IDateRange): void;
//   isDirty(): boolean;
//   resetChecksum(): void;
//   findInput(inputs: IWidgetInputBase): IWidgetInputViewModel;
//   findInputs(inputs: IWidgetInputBase[]): IWidgetInputViewModel[];
// }

export interface IThresholdViewModel {
  key: string;
  name: string;
  color: WcagColors | string;
  minimum: number;
  maximum: number;
  value: number;
  isRange: boolean;
}

export class Threshold implements IThresholdViewModel {
  public key: string = newGuid();
  public color: WcagColors = WcagColors[WcagColors.Green];
  public maximum: number = 0;
  public minimum: number = 0;
  public name: string = '';
  public value: number = 0;
  public isRange: boolean = false;

  constructor(threshold?: IThresholdViewModel) {
    if (threshold != null) {
      Object.assign(this, threshold);
      this.value = Number(threshold.value);
      this.minimum = Number(threshold.minimum);
      this.maximum = Number(threshold.maximum);
    } else {
      this.key = newGuid();
    }
  }
}

export enum WidgetInputTypeEnum {
  Unknown = 'Unknown',
  JZHCHeaterInput = 'JZHCHeaterInput',
  JZHCEmberHierarchyInput = 'JZHCEmberHierarchyInput',
  JZHCBurnerInput = 'JZHCBurnerInput'
}

export interface IWidgetInputBase {
  key: string;
  inputEntityKey: string;
  inputType: WidgetInputTypeEnum;
  customerSiteKey: string;
}

export interface IWidgetFolderInputViewModel extends IWidgetInputBase {
  equipmentConfigKey: string;
  inputName: string;
  parentEntityKey?: string;
}

export interface IWidgetInputViewModel extends IWidgetInputBase {
  axis: ReportAxis;
  equipmentConfigKey?: string;
  displayName?: string;
  inputName: string;
  displayValue: string;
  sortOrder: number;
  chartType: SeriesTypes;
  unitOfMeasurement: string;
  equals?(input: IWidgetInputBase): boolean;
  inputsWithData?: string[];

  // Client Only
  parentEntityKey?: string;
}

export class WidgetInput implements IWidgetInputViewModel {
  public axis: ReportAxis = ReportAxis.Left;
  public inputEntityKey: string = '';
  public key: string = '';
  public inputType: any;
  public equipmentKey: string = '';
  public displayName: string = '';
  public inputName: string = '';
  public displayValue: any = null;
  public sortOrder: number = 0;
  public chartType: SeriesTypes = SeriesTypes.Column;
  public customerSiteKey: string = '';
  public unitOfMeasurement: string = '';
  public inputsWithData?: string[];

  constructor(input?: IWidgetInputViewModel) {
    if (input != null) {
      Object.assign(this, input);
    }
  }

  public equals(input: IWidgetInputBase): boolean {
    return input && input.key === this.key;
  }
}

export class Widget implements IWidgetViewModel {
  public position: any = null;
  public showGridLines: boolean = false;
  public is3D: boolean = false;
  public isSyncAxisLimits: boolean = false;
  public isNormalized: boolean = false;
  public seriesPerSchedule: boolean = true;
  public dateRange: IDateRange;
  public key: string = '';
  public type: any = null;
  public size: any = null;
  public name: any = null;
  public refreshFrequency: number = NaN;
  public thresholds: Array<IThresholdViewModel> = [];
  public totalBy: TotalBy = TotalBy.None;
  public inputs: Array<IWidgetInputViewModel> = [];
  public maximum: number = 200;
  public minimum: number = 0;
  public leftUnitOfMeasure: UnitsOfMeasure = UnitsOfMeasure.None;
  public rightUnitOfMeasure: UnitsOfMeasure = UnitsOfMeasure.None;
  public useDataSampling: boolean = false;
  public inputSampleSize: number = 0;
  public selectedTime?: Date;

  public reportRangeType: any;

  public get checksum(): string {
    return JSON.stringify(this);
  }

  public set checksum(value: string) {}

  public get reportRangeTypeData(): ReportRangeType {
    return this.reportRangeType;
  }
  public set reportRangeTypeData(value: ReportRangeType) {
    this.reportRangeType = value;
    this.dateRange = ReportTimeHelper.rangeTypeToDateRange(value);
  }

  public get uniqueDataKey(): string {
    const uniqueKeys = [this.key, ...this.inputs.map(input => input.key)];
    const dataKey = uniqueKeys.sort(alphabeticSorter()).join('-');
    return dataKey;
  }

  public isDirty(): boolean {
    return this.checksum !== this.getChecksum();
  }
  public isValid(): boolean {
    if (this.type === WidgetTypes.Status && this.thresholds.length === 0) {
      return false;
    }
    if (this.inputs.length === 0) {
      return false;
    }
    if (this.type === WidgetTypes.Gauge) {
      const min = this.minimum;
      const max = this.maximum;
      if (isNaN(min) || isNaN(max) || min >= max) {
        return false;
      }
    }
    return true;
  }
  private getChecksum(): string {
    const copy: IWidgetViewModel = new Widget(this);
    copy.checksum = '';
    return JSON.stringify(copy);
  }
  public resetChecksum(): void {}

  constructor(widget?: IWidgetViewModel) {
    if (this.reportRangeType == null) {
      this.reportRangeType = ReportRangeType.Today;
    }
    this.dateRange = ReportTimeHelper.rangeTypeToDateRange(this.reportRangeType);

    if (widget != null) {
      Object.assign(this, widget);
      if (widget.thresholds != null) {
        this.thresholds = widget.thresholds.map((t: IThresholdViewModel) => new Threshold(t));
      }
      if (widget.inputs != null) {
        this.inputs = widget.inputs.map((input: IWidgetInputViewModel) => new WidgetInput(input));
      }

      this.type = widget.type;
      if (widget.size) {
        this.size = widget.size;
      } else {
        this.size = (this.type === WidgetTypes.Status || this.type === WidgetTypes.Gauge) ? WidgetSizes.Small : WidgetSizes.Medium;
      }
      this.checksum = '';
      this.resetChecksum();
      this.selectedTime = widget.selectedTime;
    } else {
      if(this.dateRange?.toDate) {
      this.selectedTime = new Date(this.dateRange?.toDate);
      }
    }
  }

  public static getPlaceholderNewImage(type: WidgetTypes): string {
    switch (type) {
      case WidgetTypes.Chart:
        return '/img/placeholder-widget-linechart-new.png';
      case WidgetTypes.Gauge:
        return '/img/placeholder-widget-gauge-new.png';
      case WidgetTypes.Status:
        return '/img/placeholder-widget-status-new.png';
      default:
        return '';
    }
  }

  public imageSource(): string {
    return Widget.getPlaceholderNewImage(this.type);
  }
}

export interface IChartWidgetViewModel extends IWidgetViewModel {
  chartSettings: IReportChartSettings;
}

export class ChartWidget extends Widget implements IChartWidgetViewModel {
  public chartSettings: IReportChartSettings | any = {};
  constructor(widget?: IChartWidgetViewModel) {
    super(widget);
    if (widget != null) {
      Object.assign(this, widget);
    }
  }
}
export interface IWidgetSnapshot {
  report: IWidgetViewModel;
  executedReport: IWidgetViewModel;
  // reportData: Array<IWidgetDataViewModel>;
  // reportSummary: IDataTable<IWidgetSummaryRow>;
  // reportTable: IDataTable<IWidgetDataRow>;
}

export interface IGaugeWidgetViewModel extends IWidgetViewModel {}

export interface IStatusWidgetViewModel extends IWidgetViewModel {}

export interface IWidgetChartInputViewModel extends IWidgetInputViewModel {
  axis: ReportAxis;
  chartType: SeriesTypes;
}

export interface IDashboardTimeRangeSetting {
  type: DashboardTimeRangesEnum;
  range?: IDateRangeObjects;
  changeByUser?: boolean;
}
