



// Libraries
import { Component, Prop } from 'vue-property-decorator';
import { interval, Observable, Subscription } from 'rxjs';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import { DateTimeInput } from '@/view-models/report-time-model';
// Services
// Stores

@Component({
  name: 'relative-time'
})
export default class RelativeTime extends BaseComponent {
  // VUE.JS Props
  @Prop()
  public time!: DateTimeInput;
  @Prop({ default: null })
  public updateInterval!: number; // milliseconds
  // VUEX
  // Properties
  // Fields
  private interval$: Observable<number> | null = null;
  private intervalSub: Subscription | null = null;
  private intervalValue: number| null = null;
  // Getters
  public get updateKey(): number {
    return new Date().valueOf() + (this.intervalValue == null ? 0 : this.intervalValue);
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.updateInterval != null) {
      this.interval$ = interval(this.updateInterval);
      this.intervalSub = this.interval$.subscribe((val) => { this.intervalValue = val; });
    }
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    if (this.intervalSub != null) {
      this.intervalSub.unsubscribe();
    }
  }
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
