import VueI18n from 'vue-i18n';
import { findLargeNumberAbbreviation } from '@/i18n/highcharts-format-helper';

export type TranslationConfigType = string | { tag: string, placeholders: VueI18n.Values };

export interface INumericUnitConfig {
  unitTranslation: TranslationConfigType;
  maxDecimalsLength?: number;
  abbreviate?: boolean,
}

export interface INumericUnitConfigTable {
  [uom: string]: INumericUnitConfig
}

export interface INumericUnitFormatter {
  formatWithTable(uom: string, value: number, extra?: INumericUnitConfig): string;
  format(value: number, config: INumericUnitConfig): string;
}

export class NumericUnitFormatter implements INumericUnitFormatter {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly i18n: VueI18n,
    protected readonly configTable?: INumericUnitConfigTable | any
  ) {}

  public formatWithTable(uom: string, value: number, extra?: Partial<INumericUnitConfig>): string {
    const config: INumericUnitConfig = Object.assign({}, this.configTable[uom] ?? {}, extra ?? {}) as any;

    return this.format(value, config);
  }

  public format(value: number, config: INumericUnitConfig): string {
    let valueNum: number = value;
    let valueString: string = `${value}`;
    let uomLabel: string = '';

    if (config == null) {
      return valueString;
    }

    if (config.abbreviate) {
      const abbr = findLargeNumberAbbreviation(value);
      if (!abbr.key == null) {
        uomLabel += `${this.i18n.t(`global.largeNumberAbbreviations.${abbr.key}Unit`).toString()} `;
      }
      valueNum = abbr.value;
      valueString = `${abbr.value}`;
    }

    if (config.maxDecimalsLength != null) {
      valueString = this.truncateDecimals(valueNum, config.maxDecimalsLength);
    }

    const labelTransArgs: [VueI18n.Path, VueI18n.Values?] = typeof config.unitTranslation === 'string'
      ? [config.unitTranslation]
      : [config.unitTranslation.tag, config.unitTranslation.placeholders];

    uomLabel += this.i18n.t(...labelTransArgs).toString();

    return `${valueString} ${uomLabel}`;
  }

  private truncateDecimals(value: number, maxDecimalsLength: number, extraOptions?: Intl.NumberFormatOptions): string {
    const max = Math.min(20, maxDecimalsLength ?? 20);
    const options = Object.assign(
      {
        useGrouping: false,
        maximumFractionDigits: max
      },
      extraOptions ?? {});

    return new Intl.NumberFormat(this.i18n.locale, options).format(value);
  }
}
