// import { getAuthUser } from '@/store-modules/user';

export class TimerLock {
  private locked: boolean = false;
  private timeout = null;

  public get isLocked() { return this.locked; }

  constructor(private readonly msThreshold: number, startNow: boolean = false) {
  }

  public tryAccess() {
    if (this.locked) { return false; }
    return true;
  }

  public async tryProcess(func: () => Promise<void>) {
    if (this.tryAccess()) {
      await func();
    }
  }
}

export abstract class LocalStorageManager<T> {
 // protected abstract get storageKey(): string;

  // eslint-disable-next-line no-useless-constructor
  public constructor() {}

//   public fetch(): T {
//     const val = localStorage.getItem(this.storageKey);
//     return JSON.parse(val);
//   }

//   public fetchOr(otherwise: T) {
//     const val = localStorage.getItem(this.storageKey);
//     if (val == null) {
//       this.save(otherwise);
//       return otherwise;
//     }
//     return JSON.parse(val);
//   }

  public save(state: T): void {
    if (state == null) {
      this.clear();
    } else {
     // localStorage.setItem(this.storageKey, JSON.stringify(state));
    }
  }

  public clear(): void {
   // localStorage.removeItem(this.storageKey);
  }
}

export class LocalStorageByUser<T> extends LocalStorageManager<T> {
  constructor(private readonly namespace: string, public uniqueKey?: string) {
    super();
  }

//   protected get storageKey(): string {
//     const uniqueKey = this.uniqueKey == null ? '' : `:${this.uniqueKey}`;
//     return `${getAuthUser().key}:${this.namespace}${uniqueKey}`;
//   }

  public setUniqueKey(uniqueKey: string): LocalStorageByUser<T> {
    this.uniqueKey = uniqueKey;
    return this;
  }
}
