


























// Libraries
import { Component, Prop } from 'vue-property-decorator';
import { toCamelCase } from '@/shared/string-utils';
import { alphabeticSorter } from '@/shared/array-utils';
// View Models
import { WidgetTypes } from '@/enums';
import { ITowerStageEquipment } from '@/view-models';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import CollapsibleBox from '@/components/common/CollapsibleBox.vue';
import WidgetContainer from '@/components/assets/tower/widgets/WidgetContainer.vue';
import ChartWidget from '@/components/assets/tower/widgets/ChartWidget.vue';
import BulletGaugeWidgetNew from '@/components/assets/tower/widgets/BulletGaugeWidget.vue';
// Services
// Stores
@Component({
  name: 'stage-equipment-section',
  components: {
    CollapsibleBox,
    WidgetContainer,
    ChartWidget,
    BulletGaugeWidgetNew
  }
})
export default class StageEquipmentSection extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public equipmentData!: ITowerStageEquipment;
  @Prop({ required: true })
  public loadingData!: boolean
  // VUEX
  // Properties
  public widgetTypesEnum = WidgetTypes;
  // Fields
  // Getters
  public get title(): string {
    if (this.equipmentData.name) {
      return this.$t(`assets.tower.equipments.types.${toCamelCase(this.equipmentData.type)}${(this.equipmentData.name).split(' ').join('')}`).toString();
    } else if (this.equipmentData.type) {
      return this.$t(`assets.tower.equipments.types.${toCamelCase(this.equipmentData.type)}`).toString();
    } else {
      return '';
    }
  }
  public get performanceIndicatorWidgets() {
    const widgets = [...this.equipmentData.indicators];
    widgets.sort(alphabeticSorter(widget => widget.displayValue));
    return widgets;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
