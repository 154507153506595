











































































// Libraries
import { Component, Emit, Prop } from 'vue-property-decorator';
import { toCamelCase } from '@/shared/string-utils';
import { cloneDeep } from 'lodash';
import { BDropdown } from 'bootstrap-vue/esm/components/dropdown';
// View Models
import { IWidgetDataTypes } from '@/view-models';
import {
  TowerDataSetNameEnum,
  TowerInputTypesEnum,
  TowerModalTypesEnum,
  TowerStatusIndicatorEnum,
  TowerUnitOfMeasurementsEnum
} from '@/enums';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import Loading from '@/components/common/Loading.vue';
import { Watch } from 'vue-property-decorator';
import { getValueYWithFixedDecimalPoint, isStageType } from '@/shared/tower-utils';

@Component({
  name: 'widget-container',
  components: {
    Loading
  }
})
export default class WidgetContainer extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public widgetData!: IWidgetDataTypes;
  @Prop({ required: true })
  public indicatorKey!: string;
  @Prop({ required: true })
  public loadingData!: boolean
  /* Configurable UI props */
  @Prop({ required: false, default: true })
  public showTitleAtBottom!: boolean;
  @Prop({ required: false, default: true })
  public showCurrentValue!: boolean;
  @Prop({ required: false, default: true })
  public showCurrentStatus!: boolean;
  @Prop({ required: false, default: true })
  public showEditThresholds!: boolean;
  @Prop({ required: false, default: false })
  public showStageNumber!: boolean;
  // VUEX
  // Properties
  public showResetZoomIcon: boolean = false;
  public TowerModalTypesEnum = TowerModalTypesEnum;
  public TowerStatusIndicatorEnum = TowerStatusIndicatorEnum;
  public statusIndicator = TowerStatusIndicatorEnum.None;
  public chartInstance : any = null;
  // Fields
  // Getters
  public get formattedTitle(): string {
    const displayValue = this.widgetData?.displayValue
        ? this.$t(`assets.tower.displayValues.${toCamelCase(this.widgetData.displayValue)}`).toString()
        : '';
    switch (this.widgetData.type) {
      case TowerInputTypesEnum.Validations:
        return this.widgetData.displayValue;
      case TowerInputTypesEnum.TowerEquipment:
      case TowerInputTypesEnum.Tower:
      case TowerInputTypesEnum.TowerStageInput:
      default:
        return displayValue;
    }
  }
  public get currentValue(): string {
    switch (this.widgetData.type) {
      case TowerInputTypesEnum.TowerEquipment:
      case TowerInputTypesEnum.TowerStageInput:
        return (this.widgetData.data?.length > 0)
            ? getValueYWithFixedDecimalPoint(this.widgetData.data[this.widgetData.data.length - 1][1])
            : '';
      case TowerInputTypesEnum.Tower:
        const measuredTotalInput: number[][] = this.widgetData?.inputs.filter((input : any) =>
            input.dataSetName === TowerDataSetNameEnum.MeasuredTotalDpvsh)[0]?.data;
        return (measuredTotalInput?.length > 0)
            ? getValueYWithFixedDecimalPoint(measuredTotalInput[measuredTotalInput.length - 1][1])
            : '';
      case TowerInputTypesEnum.Validations:
        const actualInput = this.widgetData?.inputs.filter((input : any) =>
            input.dataSetName === TowerDataSetNameEnum.Actual)[0]?.data;
        return (actualInput?.length > 0)
            ? getValueYWithFixedDecimalPoint(actualInput[actualInput.length - 1][1])
            : '';
      default:
        return '';
    }
  }

  public get uom(): string {
    return (!this.widgetData.unitOfMeasurement) ? TowerUnitOfMeasurementsEnum.empty : this.widgetData.unitOfMeasurement;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    this.setStatusIndicator();
    this.setChartInstance();
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public toggleZoomIcon(): void {
    this.showResetZoomIcon = true;
  }
  public resetZoom(): void {
    this.setChartInstance();
    this.chartInstance?.zoomOut() ;
    this.showResetZoomIcon = false;
  }
  public setChartInstance(): void {
    const chartReference: any = this.$children.find((item: any) => item.widgetChart);
    if (chartReference) {
      this.chartInstance = chartReference.$children[0].chart;
    }
  }
  public setStatusIndicator(): void {
    if (isStageType(this.widgetData.type)) {
      if (this.widgetData.data?.length === 0) {
        this.statusIndicator = TowerStatusIndicatorEnum.None;
      } else {
        (this.widgetData.inWarningLevel)
            ? this.statusIndicator = TowerStatusIndicatorEnum.Danger
            : this.statusIndicator = TowerStatusIndicatorEnum.Success;
      }
    }
  }
  public shown(unused: any): void {
    const ref = this.$refs['tower-dropdown'] as BDropdown;
    ref.show();
    ref.show();
  }
  // Event Methods
  // Emitters
  @Emit('show-modal')
  public triggerModalView(toShow: TowerModalTypesEnum) {
    // Create a single use deep copy to generate the chart instance
    const indicator = cloneDeep(this.widgetData);
    if (toShow === TowerModalTypesEnum.Fullscreen) {
      indicator.chartSettings.isFullScreen = true;
    }
    return Object.assign(indicator, { toShow });
  }
  // Watchers
  @Watch('widgetData')
  public refreshPage(): void {
      this.setStatusIndicator();
      this.showResetZoomIcon = false;
  }
}
