



































// Libraries
import { Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import { debounce } from 'lodash';
import { asDate } from '@/shared/date-time-utils';
// View Models
import { IDateRangeObjects } from '@/view-models/report-time-model';
import { InputDateRangeEnum, SkipDirectionsEnum } from '@/enums';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import DateTimePicker from '@/components/common/DateTimePicker.vue';
// Services
// Stores

@Component({
  name: 'time-range-skipper',
  components: {
    DateTimePicker
  }
})
export default class TimeRangeSkipper extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public start!: Date;
  @Prop({ default: () => new Date() })
  public end!: Date;
  @Prop({ default: true })
  public intoTheFuture!: boolean;
  @Prop({ default: false })
  public disableUpdateSkip!: boolean;

  // VUEX
  // Properties
  public debouncedTriggerSkip = debounce(this.triggerSkip.bind(this), 800);
  // Fields
  // Getters
  public get showForwardButton(): boolean {
    const isAfterNow = moment(this.end).isAfter(moment().seconds(0).milliseconds(0));
    return this.intoTheFuture || !isAfterNow;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public skipForward() {
    this.debouncedTriggerSkip(SkipDirectionsEnum.Forward);
  }
  public skipBackwards() {
    this.debouncedTriggerSkip(SkipDirectionsEnum.Backwards);
  }
  public onStartRangeChange(date: Date | moment.Moment) {
    this.triggerRangeChange({
      fromDate: asDate(date),
      toDate: this.end,
      dateType: InputDateRangeEnum.Start
    });
  }
  public onEndRangeChange(date: Date | moment.Moment) {
    this.triggerRangeChange({
      fromDate: this.start,
      toDate: asDate(date),
      dateType: InputDateRangeEnum.End
    });
  }
  // Watchers
  // Emitters
  @Emit('skip')
  public triggerSkip(dirEmit: SkipDirectionsEnum) {}
  @Emit('change')
  public triggerRangeChange(rangeEmit: IDateRangeObjects) {}
}
