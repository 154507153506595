














































































import { Component, Emit, Prop } from 'vue-property-decorator';
import ColorPicker from '@/components/common/ColorPicker.vue';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import {
  TowerInputTypesEnum,
  TowerUnitOfMeasurementsEnum
} from '@/enums';
import {
  IEquipmentIndicator,
  IObjectPropertyUpdate, IStageInputIndicator
} from '@/view-models';
import towerStore from '@/store/towerStore';

@Component({
    name: 'tower-edit-chart-settings',
    components: {
      ColorPicker,
      SelectDropdown
    }
})
export default class TowerEditChartSettings extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public widgetData!: IEquipmentIndicator | IStageInputIndicator;
  public errorMessage: string = '';
  public tempChartScale = this.widgetData.chartSettings.scale;
  private removeByUser = false;
  // Getters
  public get isChartSettingFormValid(): boolean {
    if (this.removeByUser) { return true; }
    if(this.tempChartScale) {
    if (this.tempChartScale?.max === null && this.tempChartScale?.min === null) {
      return false;
    }

    if (typeof this.tempChartScale?.max === 'string') {
      this.tempChartScale.max = parseFloat(this.tempChartScale?.max);
    }
    if (typeof this.tempChartScale?.min === 'string') {
      this.tempChartScale.min = parseFloat(this.tempChartScale.min);
    }
    if (isNaN(this.tempChartScale?.min) || isNaN(this.tempChartScale?.max)) {
      this.errorMessage = this.$t(`assets.tower.stages.thresholds.error.mustIncludeValidNumber`).toString();
      return false;
    } else if (this.tempChartScale?.min >= this.tempChartScale?.max) {
      this.errorMessage = this.$t(`assets.tower.stages.thresholds.error.rangeMinLessThanRangeMax`).toString();
      return false;
    }
    }
    return true;
  }
  public get uom(): string {
    return this.widgetData.unitOfMeasurement;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
    public updateChartScaling() {
    if (typeof this.tempChartScale.max === 'string') {
      this.tempChartScale.max = 0;
    }
    if (typeof this.tempChartScale.min === 'string') {
      this.tempChartScale.min = 0;
    }
    const equipmentKey = this.widgetData.type === TowerInputTypesEnum.TowerEquipment
        ? (this.widgetData as IEquipmentIndicator).equipmentKey
        : '';
    const propertyToUpdate: IObjectPropertyUpdate = {
      equipmentKey,
      equipmentType: this.widgetData.equipmentType,
      indicatorKey: this.widgetData.key,
      path: 'chartSettings.scale',
      value: this.tempChartScale
    };
    towerStore.updateStageWidgetData(propertyToUpdate);
  }
  public clearChartSettingAction() {
    this.tempChartScale.max = null;
    this.tempChartScale.min = null;
    this.errorMessage = '';
    this.removeByUser = true;
  }
  // Emitters
  @Emit('toggle-modal')
  public saveChartSettingAction(action: string) {
    switch (action) {
      case 'cancel':
        return false;
      case 'save':
        this.updateChartScaling();
        return false;
    }
  }
}
