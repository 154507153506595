


















// Libraries
import { Component, Emit, Prop } from 'vue-property-decorator';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
// Services

@Component({
  name: 'slide-out-panel'
})
export default class SlideOutPanel extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public isPanelOpen! : boolean;
  @Prop({ required: false, default: null })
  public title!: string;
  @Prop({ required: false, default: null })
  public icon!: string;
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // created(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('toggle-panel')
  private togglePanel() {}
}
