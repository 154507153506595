import EftTime from '@/utils/eft-time';

export interface ITime {
	hours: number;
	minutes: number;
	seconds: number;
}

export default class Time {
	public static parseFromSeconds(value: number): Time {
		const hours = Math.floor(value / EftTime.hour);
		value -= hours * EftTime.hour;

		const minutes = Math.floor(value / EftTime.minute);
		value -= minutes * EftTime.minute;

		const seconds = Math.floor(value / EftTime.second);

		return new Time({
			hours: hours === 24 ? 0 : hours,
			minutes,
			seconds
		});
	}

	public hours: number;
	public minutes: number;
	public seconds: number;

	constructor(o?: ITime) {
		if (o !== undefined) {
			if (o.hours < 0 || o.hours > 23) {
				throw new Error('Time.ts: Hour must be 24 hours format 0 - 23');
			}
			if (o.minutes < 0 || o.minutes > 59) {
				throw new Error('Time.ts: Minute must be 0-59');
			}
			if (o.seconds < 0 || o.seconds > 59) {
				throw new Error('Time.ts: Second must be 0-59');
			}
			this.hours = o.hours;
			this.minutes = o.minutes;
			this.seconds = o.seconds;
		} else {
			this.hours = 0;
			this.minutes = 0;
			this.seconds = 0;
		}
	}

	public toSeconds(): number {
		return this.hours * EftTime.hour + this.minutes * EftTime.minute + this.seconds * EftTime.second;
	}

	public toDateObject(): Date {
		const dateObject: Date = new Date();

		dateObject.setHours(this.hours);
		dateObject.setMinutes(this.minutes);
    dateObject.setSeconds(this.seconds);
    dateObject.setMilliseconds(0);

		return dateObject;
	}
}
