export enum ReportRangeType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last24Hours = 'Last24Hours',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last90Days = 'Last90Days',
  Last180Days = 'Last180Days',
  MonthToDate = 'MonthToDate',
  YearToDate = 'YearToDate',
  SpecificYear = 'SpecificYear',
  Custom = 'Custom'
}

export enum TotalBy {
  None = 'None',
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
  All = 'All'
}

export enum GraphType {
  Line = 'Line',
  Spline = 'Spline',
  Column = 'Column'
}

export enum SeriesTypes {
  Area = 'Area',
  AreaSpline = 'AreaSpline',
  Line = 'Line',
  Spline = 'Spline',
  Column3d = 'Column3d',
  Column = 'Column',
  Unknown = 'Unknown'
}

export enum ReportAxis {
  Left = 'Left',
  Right = 'Right'
}

export enum InputValues {
  // Consumption = 'Consumption',
  // Cost = 'Cost',
  // Minimum = 'Minimum',
  // Maximum = 'Maximum',
  // Average = 'Average',
  LastReadValue = 'LastReadValue',
  Draft = 'Draft',
  ZoneO2 = 'ZoneO2',
  StackNOx = 'StackNOx',
  FuelMassFlow = 'FuelMassFlow',
  TargetO2 = 'TargetO2',
  CleanTipExcessAir = 'CleanTipExcessAir',
  CleanTipExpectedDryO2 = 'CleanTipExpectedDryO2',
  CleanTipExpectedWetO2 = 'CleanTipExpectedWetO2',
  CleanTipLeakageAirFlowRate = 'CleanTipLeakageAirFlowRate',
  CleanTipHeatRelease = 'CleanTipHeatRelease',
  CustomerFlowExcessAir = 'CustomerFlowExcessAir',
  CustomerFlowExpectedDryO2 = 'CustomerFlowExpectedDryO2',
  CustomerFlowExpectedWetO2 = 'CustomerFlowExpectedWetO2',
  CustomerFlowLeakageAirFlowRate = 'CustomerFlowLeakageAirFlowRate',
  CustomerFlowHeatRelease = 'CustomerFlowHeatRelease',
  TipHealthIndicator = 'TipHealthIndicator',

}

export enum ReportInputDisplayValueEnum {
  Unknown = 'Unknown',
  Draft = 'Draft',
  ZoneO2 = 'ZoneO2',
  StackNOx = 'StackNOx',
  StackCO = 'StackCO',
  FuelMassFlow = 'FuelMassFlow',
  TargetO2 = 'TargetO2',
  CleanTipExcessAir = 'CleanTipExcessAir',
  CleanTipExpectedDryO2 = 'CleanTipExpectedDryO2',
  CleanTipExpectedWetO2 = 'CleanTipExpectedWetO2',
  CleanTipLeakageAirFlowRate = 'CleanTipLeakageAirFlowRate',
  CleanTipHeatRelease = 'CleanTipHeatRelease',
  CustomerFlowExcessAir = 'CustomerFlowExcessAir',
  CustomerFlowExpectedDryO2 = 'CustomerFlowExpectedDryO2',
  CustomerFlowExpectedWetO2 = 'CustomerFlowExpectedWetO2',
  CustomerFlowLeakageAirFlowRate = 'CustomerFlowLeakageAirFlowRate',
  CustomerFlowHeatRelease = 'CustomerFlowHeatRelease',
  TipHealthIndicator = 'TipHealthIndicator',
  StackCombustibles = 'StackCombustibles',
  ArchTemperature = 'ArchTemperature',
  MidBoxTemperature = 'MidBoxTemperature',
  FloorTemperature = 'FloorTemperature',
  AmbientPressure = 'AmbientPressure',
  LowerHeatingValue = 'LowerHeatingValue',
  LowerHeatingValueBtuscf = 'LowerHeatingValueBtuscf',
  CleanTipBurnerLambdas = 'CleanTipBurnerLambdas',
  CleanTipBurnerSubStoich = 'CleanTipBurnerSubStoich',
  CustomerFlowBurnerLambdas = 'CustomerFlowBurnerLambdas',
  CustomerFlowBurnerSubStoich = 'CustomerFlowBurnerSubStoich',
  CleanTipBurnerOutOfControllableRange = 'CleanTipBurnerOutOfControllableRange',
  CleanTipRecommendedDamperSetting = 'CleanTipRecommendedDamperSetting',
  SubStoichWarning = 'SubStoichWarning'
}
