

























































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import TowerStageTable from '@/components/assets/tower/TowerStageTable.vue';
import SlideOutPanel from '@/components/assets/tower/SlideOutPanel.vue';
import towerStore from './store/towerStore';
import { TowerStageFilterOptionEnum } from './enums';
import { ITowerStage } from './view-models';
import RelativeTime from './components/common/RelativeTime.vue';
import TowerStageCard from './components/assets/tower/TowerStageCard.vue';
import { toCamelCase } from '@/shared/string-utils';
import appStore from '@/store/appStore';
import userStore from '@/store/userStore';

@Component({
  name: 'stages',
  components: {
    TowerStageTable,
    SlideOutPanel,
    RelativeTime,
    TowerStageCard,
    SelectDropdown
  }
})
export default class Stages extends BaseComponent {
    // VUE.JS Props
  // Properties
  public isPanelOpen: boolean = false;
  public showGridView: boolean = false;
  public showListView: boolean = true;
  // VUEX
  public get tower(): any {
    return towerStore.currentTower;
  }
  public get towerGraphic(): string {
    return towerStore.currentTower?.imageSignedUrl;
  }
  public get tableFilter() {
    return towerStore.towerStageFilter;
  }
  public set tableFilter(value: TowerStageFilterOptionEnum) {
    towerStore.setTowerStageFilter(value);
  }
  // Fields
  // Getters
  public get towerTitle(): string {
    return `${this.tower?.name ?? ''}`;
  }
  public get activeAlerts(): string {
    return (this.tower?.alerts ?? 0) < 1 ? '' : this.$t('assets.tower.stages.alertsTitle', { num: this.tower.alerts }).toString();
  }
  public get hasStages(): boolean {
    return this.tower.stages.length > 0;
  }
  public get lastTowerViewCalculation(): string {
    return this.tower?.lastTowerViewCalculation;
  }
  public get filterOptions(): any {
    // Specifies display order, first is default
    const options = [ TowerStageFilterOptionEnum.All, TowerStageFilterOptionEnum.Alerts ];
    return options.map(type => ({
        text: this.$t(`assets.tower.stages.filterOptions.${toCamelCase(type)}`).toString(),
        value: type
      }));
  }
  public get organizedStages(): any {
    const stages = (this.tower?.stages ?? []).map((stage: ITowerStage) => Object.assign({}, stage));
    switch (this.tableFilter) {
      case 'All':
        return stages.sort((a: any, b: any) => a.order - b.order);
      case 'Alerts':
        return stages.sort((a: any, b: any) => b.alerts.length - a.alerts.length)
            .filter((stage : ITowerStage) => ((stage.alerts?.length ?? 0) > 0));
    }
    return stages;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted() {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public togglePanel(mEvent: MouseEvent): void {
    if (mEvent) { mEvent.preventDefault(); };
    this.isPanelOpen = !this.isPanelOpen;
  }
  public mounted() {
    towerStore.loadTower(appStore.assetKey);
    towerStore.getUomOptions(userStore.authUser?.activeCustomerKey);
  }
  public toggleView(toShow: any): void {
    switch (toShow) {
      case 'list':
        this.showListView = true;
        this.showGridView = false;
        break;
      case 'grid':
        this.showGridView = true;
        this.showListView = false;
        break;
      default:
    }
  }
  // Watchers
  // Emitters
}
