























































































































import { Component, Emit, Prop } from 'vue-property-decorator';
import ColorPicker from '@/components/common/ColorPicker.vue';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import {
  TowerEquipmentTypeEnum,
  TowerInputTypesEnum,
  TowerThresholdRangesEnum,
  TowerThresholdTypeEnum,
  TowerUnitOfMeasurementsEnum,
  WcagColors
} from '@/enums';
import {
  IEquipmentIndicator,
  IObjectPropertyUpdate,
  IStageInputIndicator,
  ITowerCustomThresholdRequest,
  ITowerThreshold,
  TowerThreshold
} from '@/view-models';
import { ISelectItem } from '@/components/types/select-box';
import towerStore from '@/store/towerStore';
import userStore from '@/store/userStore';
import { Permission } from '@/enums/permission';

@Component({
    name: 'tower-edit-threshold-settings',
    components: {
      ColorPicker,
      SelectDropdown
    }
})
export default class TowerEditThresholdSettings extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public widgetData!: IEquipmentIndicator | IStageInputIndicator;
  // Properties
  public tempThreshold: ITowerThreshold = new TowerThreshold(TowerThresholdRangesEnum.OutsideRange, 'Green', 0, 0, this.getUniqueId(), 'Range');
  public colorOptions: Array<WcagColors> = [WcagColors.Green, WcagColors.Yellow, WcagColors.Red];
  public errorMessage: string = '';
  public hasThreshold: boolean = false;
  public tempChartScale = this.widgetData.chartSettings.scale;
  private deleteThresholdByUser: boolean = false;
  // Getters
  public get thresholdAlertTypes(): Array<ISelectItem<TowerThresholdRangesEnum>> {
    return Object.values(TowerThresholdRangesEnum).map(type => ({
      text: this.$t(`assets.tower.thresholdAlertTypes.${type}`).toString(),
      value: type
    }));
  }
  public get dropdownPlaceholder(): string {
    return this.$t(`assets.tower.thresholdAlertTypes.${this.tempThreshold.alertType}`).toString();
  }
  public get isThresholdFormValid(): boolean {
    if (this.tempThreshold.minValue || this.tempThreshold.maxValue) {
      this.deleteThresholdByUser = false;
    }
    if (this.tempThreshold.thresholdType === TowerThresholdTypeEnum.Range && !this.deleteThresholdByUser) {
      if (this.tempThreshold.minValue === null && this.tempThreshold.maxValue === null) {
        return false;
      } else {
        if (typeof this.tempThreshold.minValue === 'string') {
          this.tempThreshold.minValue = parseFloat(this.tempThreshold.minValue);
        }
        if (typeof this.tempThreshold.maxValue === 'string') {
          this.tempThreshold.maxValue = parseFloat(this.tempThreshold.maxValue);
        }
        if (isNaN(this.tempThreshold.minValue) || isNaN(this.tempThreshold.maxValue)) {
          this.errorMessage = this.$t(`assets.tower.stages.thresholds.error.mustIncludeValidNumber`).toString();
          return false;
        } else if (this.tempThreshold.minValue >= this.tempThreshold.maxValue) {
          this.errorMessage = this.$t(`assets.tower.stages.thresholds.error.rangeMinLessThanRangeMax`).toString();
          return false;
        }
      }
    } else if (this.tempThreshold.thresholdType === TowerThresholdTypeEnum.Value) {
      if (typeof this.tempThreshold.maxValue === 'string') {
        this.tempThreshold.maxValue = parseFloat(this.tempThreshold.maxValue);
      }
      if (isNaN(this.tempThreshold.maxValue)) {
        this.errorMessage = this.$t(`assets.tower.stages.thresholds.error.mustIncludeValidNumber`).toString();
        return false;
      }
    }
    return true;
  }
  public get uom(): string {
    return this.widgetData.unitOfMeasurement;
  }
  public get thresholdValueLabel(): string {
    return this.tempThreshold.thresholdType === TowerThresholdTypeEnum.Range ? 'Values' : 'Value';
  }

  public get canManageTowers(): boolean {
    return userStore.hasPermission(Permission.ManageTowers);
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    this.setThreshold();
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public setThreshold(): void {
    if (this.widgetData.hasOwnProperty('thresholds')) {
      this.hasThreshold = true;
      Object.assign(this.tempThreshold, this.widgetData.thresholds[0]);
    }
  }
  // Event Methods
  public onThresholdRangeSelected(key: string) {
    switch (key) {
      case TowerThresholdRangesEnum.LessThanValue:
        this.tempThreshold.thresholdType = TowerThresholdTypeEnum.Value;
        this.tempThreshold.minValue = 0;
        this.tempThreshold.alertType = TowerThresholdRangesEnum.LessThanValue;
        break;
      case TowerThresholdRangesEnum.GreaterThanValue:
        this.tempThreshold.thresholdType = TowerThresholdTypeEnum.Value;
        this.tempThreshold.minValue = 0;
        this.tempThreshold.alertType = TowerThresholdRangesEnum.GreaterThanValue;
        break;
      case TowerThresholdRangesEnum.InsideRange:
        this.tempThreshold.thresholdType = TowerThresholdTypeEnum.Range;
        this.tempThreshold.alertType = TowerThresholdRangesEnum.InsideRange;
        break;
      case TowerThresholdRangesEnum.OutsideRange:
        this.tempThreshold.thresholdType = TowerThresholdTypeEnum.Range;
        this.tempThreshold.alertType = TowerThresholdRangesEnum.OutsideRange;
        break;
    }
    this.deleteThresholdByUser = false;
  }
  public async saveThreshold(): Promise<void> {
    if (this.tempThreshold.thresholdType === TowerThresholdTypeEnum.Value) {
      // Set so backend doesn't fail
      this.tempThreshold.minValue = 0;
    }
    const equipmentName = this.populateEquipmentName() ? (this.widgetData as IEquipmentIndicator).equipmentName
        : this.widgetData.type === TowerInputTypesEnum.TowerStageInput
            ? 'Inputs'
            : '';
    const equipmentKey = this.widgetData.type === TowerInputTypesEnum.TowerEquipment
        ? (this.widgetData as IEquipmentIndicator).equipmentKey
        : '';
    const payload: ITowerCustomThresholdRequest = {
      towerKey: towerStore.currentTower.key,
      stageKey: towerStore.currentStage.stageKey,
      equipmentKey,
      equipmentName,
      equipmentType: this.widgetData.equipmentType,
      displayName: this.widgetData.displayValue,
      alertType: this.tempThreshold.alertType,
      color: this.tempThreshold.color,
      maxValue: this.tempThreshold.maxValue,
      minValue: this.tempThreshold.minValue,
      thresholdName: this.tempThreshold.thresholdName,
      thresholdType: this.tempThreshold.thresholdType
    };
    this.deleteThresholdByUser
        ? await towerStore.removeCustomThreshold(payload)
        : await towerStore.saveCustomThreshold(payload);
    const propertyToUpdate: IObjectPropertyUpdate = {
      equipmentKey,
      equipmentType: this.widgetData.equipmentType,
      indicatorKey: this.widgetData.key,
      path: 'thresholds',
      value: this.deleteThresholdByUser ? [] : [this.tempThreshold]
    };
    towerStore.updateStageWidgetData(propertyToUpdate);
  }
  public deleteThreshold(): void {
    this.deleteThresholdByUser = true;
    this.tempThreshold = new TowerThreshold(TowerThresholdRangesEnum.OutsideRange, 'Green', 0, 0, this.tempThreshold.thresholdName, 'Range');
  }
  // Watchers
  // Emitters
  @Emit('toggle-modal')
  private emitThresholdAction(action: string) {
    switch (action) {
      case 'cancel':
        return false;
      case 'save':
        if (this.isThresholdFormValid) {
          this.saveThreshold();
        }
        return false;
      case 'delete':
        this.deleteThreshold();
        return true;
    }
  }

  private getUniqueId():string {
    return 'Name_' + Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  private populateEquipmentName(): boolean {
    return this.widgetData.equipmentType === TowerEquipmentTypeEnum.Downcomer ||
        this.widgetData.equipmentType === TowerEquipmentTypeEnum.Panel;
  }
}
