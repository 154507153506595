









// Libraries
import { Component, Prop } from 'vue-property-decorator';
import {
  Options
} from 'highcharts';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import TowerWidgetBuilder from '@/shared/tower-widget-builder';
import towerStore from '@/store/towerStore';
// Services
// Stores

@Component({
  name: 'bullet-gauge-widget'
})
export default class BulletGaugeWidget extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: false })
  public indicator! : any;// TODO Add types
  // VUEX
  // Properties
  // Fields
  private highchartsCounter: number = 0;
  // Getters
  public get uniqueId(): string {
    return `bullet-gauge-graph: ${this.cid}`;
  }
  public get chartOptions(): Options {
    return new TowerWidgetBuilder(this.indicator, this.timeSelections, this.$i18n, this).buildWidget();
  }
  public get timeSelections() {
    return towerStore.currentTimeSelections;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    window.addEventListener('resize', this.onResize);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }
  // destroyed(): void {}
  // Private Methods
  private triggerChartRender(): void {
     this.highchartsCounter++;
  }
  // Helper Methods
  // Event Methods
  public onResize() {
    this.triggerChartRender();
  }
  // Emitters
  // Watchers
}
