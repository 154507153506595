


















// Libraries
import { Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
// Services
// Stores

@Component({
  name: 'collapsible-box'
})
export default class CollapsibleBox extends BaseComponent {
  // VUE.JS Props
  @Prop()
  public title!: string | TranslateResult;
  @Prop({ required: false, default: false })
  public initiallyExpanded!: boolean;

  // VUEX
  // Properties
  public showContent: boolean = false;

  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    this.showContent = this.initiallyExpanded;
  }
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public toggleExpansion() {
    this.showContent = !this.showContent;
  }
  // Watchers
  // Emitters
}
