








import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { asDate } from '@/shared/date-time-utils';
// View Models
import { DateTimeInput } from '@/view-models/report-time-model';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';

@Component({
  name: 'date-time-picker'
})
export default class DateTimePicker extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public value!: DateTimeInput;
  @Prop({ default: false })
  public wrap!: boolean;
  @Prop({ default: 1000 })
  public debounceTime!: number;
  @Prop({ default: true })
  public allowFuture!: boolean;
  @Prop({ default: null })
  public maxDate!: DateTimeInput;
  @Prop({ default: null })
  public minDate!: DateTimeInput;
  @Prop({ default: false })
  public showClear!: boolean;
  @Prop({ default: false })
  public disableUpdateSkip!: boolean;

  public dp: Instance | any = null;
  public isCleared: boolean = false;
  public elem: any;
  public input$: Subject<DateTimeInput> = new Subject<DateTimeInput>();
  public inputSub: Subscription = this.input$
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(this.inputSubHandler);
  protected dateFormat: string = 'm/d/Y H:i:S';
  protected altFormat: string = 'm/d/Y h:i K';
  private skipSubscriptionTrigger: boolean = false;

  private inputSubHandler(dateVal: DateTimeInput) {
    if (this.skipSubscriptionTrigger) {
      this.skipSubscriptionTrigger = false;
      return;
    }

    if (typeof dateVal === 'boolean') {
      if (!this.showClear) {
        // Don't allow blanks. Use the previous value
        dateVal = this.dp.selectedDates[0];
      }
    }

    this.triggerInput(asDate(dateVal));
  }

  // Fields
  // Getters
  public get valueDate() {
    return asDate(this.value);
  }

  public get showClearDateIcon(): boolean {
    return (this.dp && this.dp.selectedDates.length && this.showClear);
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.dp) {
      return;
    }

    this.elem = (this.wrap ? this.$el.parentNode : this.$refs.input) as HTMLElement;

    flatpickr.defaultConfig.prevArrow = "<i class='mdi mdi-chevron-left'></i>";
    flatpickr.defaultConfig.nextArrow = "<i class='mdi mdi-chevron-right'></i>";

    this.dp = flatpickr(this.elem, {
      enableTime: true,
      altFormat: this.altFormat,
      altInput: true,
      dateFormat: this.dateFormat,
      minuteIncrement: 1,
      maxDate: !this.allowFuture ? moment().add('day').toDate() : null as any
    });

    this.updateDates();

    this.registerEvents();
  }

  public clearDate(): void {
    if (this.dp) {
      this.dp.clear();
      this.triggerInput(null);
    }
  }

  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    if (this.dp) {
      this.dp.destroy();
      this.dp = null;
      this.elem = null;
    }
    if (this.inputSub != null) {
      this.inputSub.unsubscribe();
    }
    if (this.input$ != null) {
      this.input$.complete();
    }
  }
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private registerEvents() {
    this.dp.config.onChange.push(this.onChange);
    this.dp.config.onOpen.push(() => this.showEvent());
  }

  // // Event Methods

  public onChange(dateStr: string) {
    this.input$.next(dateStr);
  }

  private updateDates() {
    if (this.dp === null) { return; }
    if (this.minDate && this.maxDate && this.minDate <= this.maxDate) {
      this.dp.config.maxDate = asDate(this.maxDate);
      this.dp.config.minDate = asDate(this.minDate);
    } else if (this.minDate && !this.maxDate) {
      this.dp.config.minDate = asDate(this.minDate);
      this.dp.config.maxDate = !this.allowFuture ? asDate(moment().add('day')) : null;
    } else if (this.maxDate && !this.minDate) {
      this.dp.config.maxDate =  asDate(this.maxDate);
    } else {
      this.dp.config.maxDate = !this.allowFuture ? asDate(moment().add('day')) : null;
    }
    if (this.value) { this.dp.setDate(asDate(this.value), true, this.dateFormat); }
  }

  // Watchers
  @Watch('value')
  public valueChanged() {
    if (!this.showClear && !this.disableUpdateSkip) {
      this.skipSubscriptionTrigger = true;
    }
    this.updateDates();
  }

  @Watch('maxDate')
  public maxChanged() {
    this.updateDates();
  }
  @Watch('minDate')
  public minChanged() {
    this.updateDates();
  }

  // Emitters
  @Emit('input')
  public triggerInput(valEmit : any) {}
  @Emit('dp-show')
  public showEvent() {}
}
