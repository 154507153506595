import moment from 'moment';
import {
	EftDaysOfWeek,
	EftMonths, InputDateRangeEnum,
	ReportRangeType,
	TotalBy,
	WidgetRangeType
} from '@/enums';
import { ITimeSpan } from '@/view-models/time-span-model';

export interface IReportTimeInclusion {
	timeSpan: ITimeSpan[];
	daysOfWeek: EftDaysOfWeek[];
	months: EftMonths[];
}
export class ReportTimeInclusion implements IReportTimeInclusion {
	public timeSpan: ITimeSpan[] = [];
	public daysOfWeek: EftDaysOfWeek[] = [];
	public months: EftMonths[] = [];

	constructor(reportTimeInclusion?: IReportTimeInclusion) {
		if (reportTimeInclusion != null) {
			Object.assign(this, reportTimeInclusion);
		}
	}
}
export interface IDateRangeObjects {
	fromDate: Date | any;
	toDate: Date | any;
	dateType?: InputDateRangeEnum;
}

export interface IDateRange {
	fromDate: string;
	toDate?: string;
}
export class DateRange implements IDateRange {
	public fromDate: any = null;
	public toDate: any = null;

	constructor(dateRange?: IDateRange) {
		if (dateRange != null) {
			Object.assign(this, dateRange);
		}
	}

	public static prepareRangeForServer(range: IDateRange | any): IDateRange {
		const from: Date = new Date(range.fromDate);
		const to: Date = new Date(range.toDate);
		return new DateRange({
      fromDate: asServerDateTime(from),
      toDate: asServerDateTime(to)
		});
	}
}

export function dateObjectsToDateRange(range: IDateRangeObjects): IDateRange {
  return new DateRange({
    fromDate: asServerDateTime(range.fromDate),
    toDate: asServerDateTime(range.toDate)
  });
}

export type DateTimeInput = string | Date | moment.Moment | number | any;

export function asServerDateTime(val: DateTimeInput) {
  return moment(val).toISOString();
}

export interface ITimePeriod {
	dateRange: IDateRange;
	inclusion: IReportTimeInclusion;
}
export class TimePeriod implements ITimePeriod {
	public dateRange: IDateRange = new DateRange();
	public inclusion: IReportTimeInclusion = new ReportTimeInclusion();

	constructor(timePeriod?: ITimePeriod) {
		if (timePeriod != null) {
			Object.assign(this, timePeriod);
			this.dateRange = new DateRange(timePeriod.dateRange);
		}
	}
}

export interface IReportTime {
	reportRangeType: ReportRangeType;
	originReportRangeType: ReportRangeType;
	year: number;
	timePeriod: ITimePeriod;
}
export class ReportTime implements IReportTime {
	public reportRangeType: ReportRangeType = ReportRangeType.Today;
	public originReportRangeType: ReportRangeType = ReportRangeType.Today;
	public year: number = new Date().getFullYear() - 1;
	public timePeriod: ITimePeriod = new TimePeriod();

	constructor(reportTime?: IReportTime) {
		if (reportTime != null) {
			Object.assign(this, reportTime);
			this.timePeriod = new TimePeriod(reportTime.timePeriod);
		}
	}
}

export interface IReportTimeOptions {
	totalBy: TotalBy;
	reportTime: IReportTime;
}
export class ReportTimeOptions implements IReportTimeOptions {
	public totalBy: TotalBy = TotalBy.None;
	public reportTime: IReportTime = new ReportTime();

	constructor(reportTimeOptions?: IReportTimeOptions) {
		if (reportTimeOptions != null) {
			Object.assign(this, reportTimeOptions);
			this.reportTime = new ReportTime(reportTimeOptions.reportTime);
		}
	}
}

export interface IWidgetTime {
	widgetRangeType: WidgetRangeType;
	originWidgetRangeType: WidgetRangeType;
	year: number;
	timePeriod: ITimePeriod;
}
export class WidgetTime implements IWidgetTime {
	public widgetRangeType: WidgetRangeType = WidgetRangeType.Today;
	public originWidgetRangeType: WidgetRangeType = WidgetRangeType.Today;
	public year: number = new Date().getFullYear() - 1;
	public timePeriod: ITimePeriod = new TimePeriod();

	constructor(widgetTime?: IWidgetTime) {
		if (widgetTime != null) {
			Object.assign(this, widgetTime);
			this.timePeriod = new TimePeriod(widgetTime.timePeriod);
		}
	}
}

export interface IWidgetTimeOptions {
	totalBy: TotalBy;
	widgetTime: IWidgetTime;
}
export class WidgetTimeOptions implements IWidgetTimeOptions {
	public totalBy: TotalBy = TotalBy.None;
	public widgetTime: IWidgetTime = new WidgetTime();

	constructor(widgetTimeOptions?: IWidgetTimeOptions) {
		if (widgetTimeOptions != null) {
			Object.assign(this, widgetTimeOptions);
			this.widgetTime = new WidgetTime(widgetTimeOptions.widgetTime);
		}
	}
}
