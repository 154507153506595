import moment from 'moment';
import { DateTimeInput } from '@/view-models/report-time-model';
import { UnixEpochTime } from '@/view-models';

export function toRelativeTime(dateTime: DateTimeInput): string {
  if (dateTime == null) {
    return '';
  }
  const m = moment(dateTime);
  return m.fromNow();
}

// EDGE does not want the T to be removed from date string. asIs is an optional parameter that determines if T is removed or not
export function asDate(date: any, asIs: boolean = false) {
  if (date == null || Array.isArray(date) && date.length < 1) {
    return null;
  } else if (date instanceof Date || date[0] instanceof Date) {
    if(date.length > 0) {
      return isNaN(date[0].valueOf()) ? null : date[0] as Date;
    }
    return isNaN(date.valueOf()) ? null : date as Date;
  } else if (typeof date === 'number') {
    return new Date(date);
  } else if (moment.isMoment(date)) {
    return (date as moment.Moment).toDate();
  } else if (typeof date === 'string') {
    if (!asIs) {
      date = date.replace('T', ' ');
    }
    return new Date(Date.parse(date));
  } else if (typeof date === 'boolean') {
    if (!date) {
      return null;
    }
  } else {
    throw new TypeError('Bad date type: ' + date);
  }
}

export function asNumericDateString(date: any): string {
  if (date instanceof Date) {
    return moment(date).format('MM/DD/YY h:mm a');
  } else {
    throw new TypeError('Bad date type: ' + date);
  }
}

export function asUnixTimestamp(date: any): UnixEpochTime | any {
  const sureDate = asDate(date);
  return sureDate == null ? null : sureDate.valueOf();
}
