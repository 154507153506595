import Vue from 'vue';
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import solidGaugeInit from 'highcharts/modules/solid-gauge';
import bulletGaugeInit from 'highcharts/modules/bullet';
import noData from 'highcharts/modules/no-data-to-display';
// import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';

More(Highcharts);
Highcharts3d(Highcharts);
stockInit(Highcharts);
noData(Highcharts);
solidGaugeInit(Highcharts);
bulletGaugeInit(Highcharts);
// exporting(Highcharts);
exportData(Highcharts);

export default () => {
  Vue.use(HighchartsVue, {
    highcharts: Highcharts
  });
};
