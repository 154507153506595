export interface ITimeOfUseViewModel {
	id: number;
	name: string;
	color: string;
}

export class TimeOfUseViewModel implements ITimeOfUseViewModel {
	public id: number = 0;
	public name: string = '';
	public color: string = '';

	constructor(timeOfUse?: ITimeOfUseViewModel) {
		if (timeOfUse != null) {
			Object.assign(this, timeOfUse);
		}
	}
}
