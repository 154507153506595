









import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import Stages from '@/Stages.vue';
import Validations from '@/Validations.vue';
import { TowerTypes } from '@/enums/home-tabs';
import Loading from '@/components/common/Loading.vue';
import appStore from '@/store/appStore';
import userStore from '@/store/userStore';

@Component({
  name: 'Home',
  components: {
    Stages,
    Validations,
    Loading
  }
})
export default class Home extends BaseComponent {

  public towerTypes = TowerTypes;
  public dataLoaded = false;
  public currentChildApp: string = '';

  public get userData() {
    return userStore.authUser?.activeCustomerKey;
  }

  public get childApp() {
    return appStore.currentChildApp;
  }

  public mounted() {
    userStore.getPermissions();
  }
}
