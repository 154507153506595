import { authGuard } from '@/utils/auth';
import VueRouter from 'vue-router';
import Home from '@/pages/Home.vue';
import Vue from 'vue';
import { BaseProvider } from '@/utils/providers/base-provider';
import { appRouteBase } from '@/utils/from-parent';
import appStore from '@/store/appStore';
import SelectedStage from '@/pages/_key.vue';

Vue.use(VueRouter);

export enum PagesEnum {
  home = 'Home',
  selectedStage = 'SelectedStage'
}

const routes = [
  { path: '*', component: Home, name: PagesEnum.home },
  { path: '/:key', component: SelectedStage, name: PagesEnum.selectedStage }
];

class RouterProvider extends BaseProvider<VueRouter> {
  constructor() {
    super('Router');
    this.instance = new VueRouter({
      mode: 'history',
      base: appRouteBase(),
      routes,
    });
  }

  public init(): void {
    this.instance.beforeEach(authGuard);
    this.instance.afterEach(() => {
      appStore.updateIsLoading(false);
    });
  }
}

export default new RouterProvider();
