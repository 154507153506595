



























// Libraries
import { Component, Prop, Emit } from 'vue-property-decorator';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import Loading from '@/components/common/Loading.vue';
// Services
// Stores
@Component({
  name: 'tower-modal',
  components: {
    Loading
  }
})
export default class TowerModal extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public value!: boolean;
  @Prop({ required: true })
  public title!: string;
  @Prop({ required: false, default: null })
  public subtitle!: string;
  @Prop({ required: false, default: 'xl' })
  public size!: string;
  @Prop({ required: false, default: true })
  public centered!: boolean;
  @Prop({ required: false, default: true })
  public hideFooter!: boolean;
  @Prop({ required: false, default: null })
  public loadingData!: boolean
  // VUEX
  // Properties
  // Fields
  // Getters
  public get showModal(): boolean {
    return this.value;
  }
  public set showModal(value: boolean) {
    this.toggleModal(value);
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('hide-modal')
  public toggleModal(value: boolean) {
    return value;
  }
}
