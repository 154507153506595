import { AxiosInstance } from 'axios';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models';
import ControllerBaseService from './common/controller-base-service';
import { carHttp } from './common/http';

export default class CustomerPreferenceService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('dv/customerPreference', axios);
  }

  public static createDefault(): CustomerPreferenceService {
      return new CustomerPreferenceService(carHttp.instance);
  }

  /**
   * Get dashboard config
   * @returns {Promise<IAvailableMeasurementSystemsViewModel>}
   */
   public getAvailableMeasurementSystems(customerKey: string): Promise<IAvailableMeasurementSystemsViewModel[]> {
       return this.httpGet<IAvailableMeasurementSystemsViewModel[]>(`availableMeasurementSystems/${customerKey}`);
  }
}