















// Libraries
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ParentEvents, configureEventBuses } from '@/utils/event-bus';
import { parentEventBus, appRouteBase } from '@/utils/from-parent';
import { TowerTypes } from '@/enums/home-tabs';
import router from './router';
import i18n from '@/utils/i18n';
import config from '@/config';
import auth from '@/utils/auth';
import { initializeHttpClients } from '@/services/common/http';
import vuePlugins from '@/plugins';
// View Models
// Components
import AppReady from '@/components/mixins/app-ready';
// Store
import store from '@/store/';
import appStore from '@/store/appStore';
import userStore from '@/store/userStore';
import towerStore from '@/store/towerStore';
import Vue from 'vue';
import { toRelativeTime } from './shared/date-time-utils';
// Initializing the app global services/configs/utilities
// There is an isAppInitialized property on the app store
// to know when the app is ready
(async function initializeApp() {
  appStore.setIsAppInitialized(false);

  // Config
  await config.init();
  // Auth
  await auth.init();
  // Http Client
  initializeHttpClients();
  // Router
  router.init();
  // Vue Plugins
  vuePlugins();
  // Events Buses
  configureEventBuses();

  appStore.setIsAppInitialized(true);
  await userStore.currentUser();
  appStore.updateIsLoading(false);
})();

Vue.filter('relative', toRelativeTime);
@Component({
  name: 'app',
  components: {
    Error : () => import('@/components/modals/Error.vue'),
    Loading : () => import('@/components/common/Loading.vue')
  },
  store,
  router: router.instance,
  i18n
})
export default class App extends Mixins(AppReady) {
  // VUE.JS Props
  @Prop({ default: '' })
  public diagramApp!: string;

  @Prop({ default: true })
  public isStandAlone!: boolean;

  public towerTypes = TowerTypes;
  public dataLoaded = false;

  @Prop({ required: true })
  public assetkey!: string;
  // Refs
  // VUEX
  public get isLoading() {
    return appStore.isLoading || !appStore.isAppInitialized;
  }

  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created() {}
  // beforeMount(): void {}
  public mounted() {
    appStore.setAssetKey(this.assetkey);
    appStore.setIsStandAlone(this.isStandAlone);
    appStore.setCurrentChildApp(this.diagramApp);
    this.setRouteChangeHandler(true);
    parentEventBus()?.$on(ParentEvents.RouteChange, this.handleRouteChange);
    appStore.setCurrentChildApp(this.diagramApp);
    parentEventBus()?.$on(
      ParentEvents.RouteChange,
      this.handleRouteChange
    );
  }
  // ready() {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  private deactivated() {
    // Get rid of old vue instance
    towerStore.clearTowerData();
    this.$destroy();
    this.setRouteChangeHandler(false);
    // Used when integrated into parent app
    appStore.updateIsLoading(true);
    parentEventBus()?.$off(ParentEvents.RouteChange, this.handleRouteChange);
  }
   private setRouteChangeHandler(on: boolean) {
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      const method = on ? '$on' : '$off';
      (window as any).eftEventBus[method](
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }
  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.startsWith(appRouteBase())) {
      appStore.updateIsLoading(false);
      let relativeRoute = parentRoute.path.replace(appRouteBase(), '/');
      relativeRoute = relativeRoute.replace(/\/\//g, '/');
      if (relativeRoute !== this.$route.path) {
        this.$router.push(relativeRoute);
      }
    }
  }
}
