import { IUser } from "@/store/types/user";
import { AxiosInstance } from "axios";
import ControllerBaseService from "./common/controller-base-service";
import { baseHttp } from "./common/http";

export default class UserService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('users', axios);
  }

  public static createDefault(): UserService {
      return new UserService(baseHttp.instance);
  }

  public getUser(): Promise<IUser> {
    return this.httpGet<IUser>('current');
  }

  public getPermissions(): Promise<string[]> {
    return this.httpGet<string[]>('userPermission');
  }
}