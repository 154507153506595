import { Point, TooltipFormatterContextObject } from 'highcharts';
import VueI18n from 'vue-i18n';
import HelperMethods from '@/shared/helper-methods';
import { IDateRange } from '@/view-models/report-time-model';
import { ReportInputDisplayValueEnum } from '@/enums';
import { UnitOfMeasurementEnum } from '@/enums';
import { IThresholdViewModel } from '@/view-models';
import { NumberFormatKey } from './number-formats';

export class HighchartFormatHelper {
  protected vueI18n: VueI18n;

  constructor(vueI18n: VueI18n) {
    this.vueI18n = vueI18n;
  }

  public abbreviateLargeNumber(value: number): string {
    const translation = findLargeNumberAbbreviation(value);
    if (translation.key === null) {
      return value.toString();
    }
    return this.vueI18n.t(`global.largeNumberAbbreviations.${translation.key}`, { value: translation.value }).toString();
  }

  public getValueYWithFixedDecimalPoint(value: number, removeTrailingZeroes?: boolean): string {
    const absoluteValue = Math.abs(value);
    if (absoluteValue < 1 && absoluteValue > 0) {
      return removeTrailingZeroes ? `${+value.toFixed(4)}` : value.toFixed(4);
    }
    return removeTrailingZeroes ? `${+value.toFixed(2)}` : value.toFixed(2);
  }

  public dateNumeric(dateValue: Date, dateRange: IDateRange): string {
    const formatType: string = resolveDateFormatString(dateRange, 'numericDateShortened');
    return this.vueI18n.d(dateValue, formatType);
  }

  public date(dateValue: Date, dateRange: IDateRange): string {
    const formatType: string = resolveDateFormatString(dateRange, 'dateShortenedWithYear');
    return this.vueI18n.d(dateValue, formatType);
  }

  public uomLabel(uom: UnitOfMeasurementEnum): string {
    const tag = `global.uom.${uom}`;
    const label = this.vueI18n.te(tag) ? `${this.vueI18n.t(tag)}` : null;
    return String.isNullOrWhiteSpace(label) ? '' : ` ${label}`;
  }

  public getPointTooltip(
    point: Point | TooltipFormatterContextObject,
    formattedValue: number,
    uom: UnitOfMeasurementEnum,
    flags?: { [key: string]: boolean },
    thresholds?: IThresholdViewModel[]
  ): string {
    if (point == null) {
      return '';
    }
    const localizedUom = this.uomLabel(uom);
    const localizedValue = this.vueI18n.n(formattedValue, NumberFormatKey.Decimal);
    const tag = `dashboard.thresholds.threshold`;
    const localizedFindThreshold = thresholds && thresholds.length
      ? thresholds.find((t: IThresholdViewModel) => formattedValue >= t.minimum && formattedValue <= t.maximum)
      : '';
      return `<span style="max-width: 100%; display: block; color: #FFFFFF;">
      <span style="color: ${point.color}; padding-right: 0.3rem">\u25CF</span>${point.series.name}
      <b style="display: inline-block; padding-left: 0.3rem; white-space: nowrap">
      ${localizedValue}${localizedUom}</b><br>
      <span>
        <span style="color:${localizedFindThreshold ? localizedFindThreshold.color : '#393C4B'};">\u25CF</span>
        <span>${localizedFindThreshold ? (localizedFindThreshold.name ? ' ' + localizedFindThreshold.name : this.vueI18n.t(tag)) : ''}</span>
      </span>
      </span>`;
  }

  public wrapYValueTooltipTemplate(
    point: Point | TooltipFormatterContextObject | any,
    formattedValue: string,
    thresholds?: IThresholdViewModel[]
  ): string {
    if (point == null) {
      return '';
    }
    const targetThresholds = (thresholds ?? []).filter((t: IThresholdViewModel) =>
      t.isRange ? point.y >= (t.minimum ?? 0) && point.y <= (t.maximum ?? 9e9) : point.y >= t.value);
    const thresholdRow = targetThresholds.reduce((row, threshold) => {
      if (threshold?.name != null) {
        let rangeValueText = threshold.isRange
          ? `${threshold.minimum ?? 0} - ${threshold.maximum ?? ''}`
          : `${threshold.value ?? ''}`;
        rangeValueText = String.isNullOrWhiteSpace(rangeValueText) ? '' : ` (${rangeValueText})`;
        row += `<span style="display: inline-block; padding-left: 1rem;">
          <span style="color: ${threshold.color ?? '#393C4B'};">\u25CF</span>
          <span>${this.vueI18n.t('dashboard.thresholds.threshold')}: ${threshold.name}${rangeValueText}</span>
        </span>`;
      }
      return row;
    }, '');

    return `<div class="tooltip-content" style="max-width: 100%; display: block; color: #FFFFFF;">
      <span style="color: ${point.color}; padding-right: 0.3rem">\u25CF</span>${point.series.name}
      <b style="display: inline-block; padding-left: 0.3rem; white-space: nowrap">${formattedValue}</b>
      <br>
      ${thresholdRow}
    </div>`;
  }
}

function resolveDateFormatString(dateRange: IDateRange | any, format: string): string {
  const fromDate = HelperMethods.asMoment(dateRange.fromDate);
  const toDate = HelperMethods.asMoment(dateRange.toDate);
  const differentDay = toDate != null && fromDate != null && !toDate.isSame(fromDate, 'day');
  return differentDay ? format : 'time';
}

export function stringToUom(uomString: string): UnitOfMeasurementEnum {
 // return UnitOfMeasurementEnum[uomString] != null ? UnitOfMeasurementEnum[uomString] : UnitOfMeasurementEnum.Default;
 return UnitOfMeasurementEnum.Default;
}

export function stringToDisplayValue(displayValue: string): ReportInputDisplayValueEnum {
  // return ReportInputDisplayValueEnum[displayValue] != null
  //   ? ReportInputDisplayValueEnum[displayValue]
  //   : ReportInputDisplayValueEnum.Unknown;
  return ReportInputDisplayValueEnum.Unknown;
}

export function findLargeNumberAbbreviation(value: number) {
  let key = null;

  if (value >= 1000000000000 || value <= -1000000000000) {
    value = value / 1000000000000;
    key = 'trillion';
  } else if (value >= 1000000000 || value <= -1000000000) {
    value = value / 1000000000;
    key = 'billion';
  } else if (value >= 1000000 || value <= -1000000) {
    value = value / 1000000;
    key = 'million';
  } else if (value >= 1000 || value <= -1000) {
    value = value / 1000;
    key = 'thousand';
  }

  return { key, value };
}
