
















































// Libraries
import { Component, Prop, Emit, Ref } from 'vue-property-decorator';
import { Options } from 'highcharts';
// View Models
import { IWidgetDataTypes } from '@/view-models';
import { TowerInputTypesEnum, TowerUnitOfMeasurementsEnum } from '@/enums';
import { newGuid, toCamelCase } from '@/shared/string-utils';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import { Chart } from 'highcharts-vue';
// Services
import TowerWidgetBuilder from '@/shared/tower-widget-builder';
import { getValueYWithFixedDecimalPoint } from '@/shared/tower-utils';
// Store
import towerStore from '@/store/towerStore';

@Component({
  name: 'chart-widget',
  components: {
    Chart
  }
})
export default class ChartWidget extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public widgetData!: IWidgetDataTypes;
  @Prop({ required: false, default: false })
  public readonly showStageNumber!: boolean;
  // VUEX
  // Properties
  public chartPointData : any = null;
  public showResetZoomIcon: boolean = false;
  // Refs
  @Ref()
  private readonly widgetChart!: any;
  // Fields
  // Getters
  public get chartOptions(): Options {
    return new TowerWidgetBuilder(this.widgetData, this.timeSelections, this.$i18n, this).buildWidget();
  }
  public get chartPointDataY(): string {
    return getValueYWithFixedDecimalPoint(this.chartPointData?.y);
  }
  public get uom(): string {
    if (this.widgetData.type === TowerInputTypesEnum.Validations) {
      return (this.widgetData.uom === TowerUnitOfMeasurementsEnum.none) ? TowerUnitOfMeasurementsEnum.empty : this.$t(`assets.tower.uoms.${toCamelCase(this.widgetData.uom)}`).toString();
    } else {
      return (!this.widgetData.unitOfMeasurement) ? TowerUnitOfMeasurementsEnum.empty : this.widgetData.unitOfMeasurement;
    }
  }
  public get timeSelections() {
    return towerStore.currentTimeSelections;
  }
  public get dataKey(): string {
    switch (this.widgetData.type) {
      case (TowerInputTypesEnum.TowerEquipment):
        return `Chart:${this.widgetData.equipmentKey}`;
      case (TowerInputTypesEnum.Validations):
        return `Chart:${this.widgetData.validationKey}`;
      default:
        return newGuid();
    }
  }
  public get dataTestHook(): string {
    switch (this.widgetData.type) {
      case (TowerInputTypesEnum.TowerEquipment):
        return this.widgetData.displayValue;
      case (TowerInputTypesEnum.Validations):
        return this.widgetData.displayValue;
      default:
        return newGuid();
    }
  }
  public get dataType(): string {
    return this.widgetData.chartSettings.widgetType;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    window.addEventListener('resize', this.triggerRender);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    window.removeEventListener('resize', this.triggerRender);
  }
  // destroyed(): void {}
  // Private Methods
  private triggerRender() {
    // EXAMPLE: must pass in an object (empty or with values to be updated)
    // const subtitle = {
    //   subtitle: {
    //     text: 'Plain Dog'
    //   }
    // };
    this.updateChart({});
  }
  // Helper Methods
  // Event Methods
  public updateChart(chartOptions: Options): void {
    // Pass chartOptions that only contain props you want to change, redraw set to true
    this.widgetChart?.chart?.update(chartOptions, true);
  }
  public buildChartLegend(data : any, time : string, thresholds : string[]): void {
    if (data) {
      this.chartPointData = data;
      this.chartPointData.time = time;
    }
    if (thresholds.length > 0) {
      this.chartPointData.thresholds = thresholds[0];
    }
  }
  public resetZoom(): void {
    this.widgetChart.chart.zoomOut();
    this.showResetZoomIcon = false;
  }

  // Emitters
  @Emit('toggle-zoom-icon')
  public toggleZoomResetIcon(): void {
    this.showResetZoomIcon = true;
  }
  // Watchers
}
