import Vue from 'vue';
import Vuex from 'vuex';
import { ErrorStore } from './errorStore';
import { AppStore } from './appStore';
import {TowerStore} from './towerStore';
Vue.use(Vuex);

interface IState {
  app: AppStore;
  error: ErrorStore;
  tower: TowerStore;
}

export default new Vuex.Store<IState>({});
