




































// Libraries
import { Component, Prop } from 'vue-property-decorator';
// View Models
import { ITowerStage } from '@/view-models/stages/tower-view-models';
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
// import RelativeTime from '@/components/shared/RelativeTime.vue';
import TowerStageTableRow from '@/components/assets/tower/TowerStageTableRow.vue';

@Component({
  name: 'tower-stage-table',
  components: {
    TowerStageTableRow,
    // RelativeTime
  }
})
export default class TowerStageTable extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public stages!: ITowerStage | any;
  // Properties
  public sortStageDesc: boolean = true;
  public sortAlertsDesc: boolean = true;
  public toggleAll: boolean = true;
  // VUEX
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted() {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private hasEquipment(stage: any) {
    return (stage.equipment?.length ?? 0) > 0;
  }
  private numberOfAlerts(stage: any) {
    return stage.alerts?.length ?? 0;
  }
  private alertPriority(stage: any) {
    // "Good" status treated as lowest priority (-1) so it will be first when sorting descending.
    return this.hasEquipment(stage)
        ? this.numberOfAlerts(stage) > 0 ? this.numberOfAlerts(stage) : -1
        : 0;
  }
  // Event Methods
  public sortStage(): void {
    this.sortStageDesc = !this.sortStageDesc;
    this.sortStageDesc ? this.stages.sort((a: any, b: any) => a.order - b.order)
        : this.stages.sort((a: any, b: any) => b.order - a.order);
  }
  public sortAlerts(): void {
    this.sortAlertsDesc = !this.sortAlertsDesc;
    this.sortAlertsDesc ? this.stages.sort((a: any, b: any) => this.alertPriority(a) - this.alertPriority(b))
        : this.stages.sort((a: any, b: any) => this.alertPriority(b) - this.alertPriority(a));
  }
  public toggleAllStages(): void {
    this.toggleAll = !this.toggleAll;
  }
  // Emitters
}
