



























// Libraries
import { Component, Prop } from 'vue-property-decorator';
import CollapsibleBox from '@/components/common/CollapsibleBox.vue';
import WidgetContainer from '@/components/assets/tower/widgets/WidgetContainer.vue';
import ChartWidget from '@/components/assets/tower/widgets/ChartWidget.vue';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import { ITowerStageInput } from '@/view-models';
import { alphabeticSorter } from '@/shared/array-utils';
import { WidgetTypes } from '@/enums';
// View Models
// Components
// Services
// Store

@Component({
  name: 'stage-input-section',
  components: {
    CollapsibleBox,
    WidgetContainer,
    ChartWidget
  }
})
export default class StageInputSection extends BaseComponent {
// VUE.JS Props
  @Prop({ required: true })
  public stageInputData!: ITowerStageInput;
  @Prop({ required: true })
  public loadingData!: boolean
// VUEX
// Properties
  public widgetTypesEnum = WidgetTypes;
// Fields
// Getters
  public get performanceIndicatorWidgets() {
    const widgets = [...this.stageInputData.indicators];
    widgets.sort(alphabeticSorter(widget => widget.displayValue));
    return widgets;
  }
// Lifecycle Handlers
// beforeCreate(): void {}
// created(): void {}
// beforeMount(): void {}
// mounted(): void {}
// beforeUpdate(): void {}
// updated(): void {}
// beforeDestroy(): void {}
// destroyed(): void {}
// Private Methods
// Helper Methods
// Event Methods
// Watchers
// Emitters
}
