





















// Libraries
import { Component, Emit } from 'vue-property-decorator';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import towerStore from '@/store/towerStore';

@Component({
  name: 'tower-edit-global-settings',
  components: {
  }
})
export default class TowerEditGlobalSettings extends BaseComponent {
  // VUE.JS Props
  // Properties
  private initialDataSampleSize: number = 0;
  // VUEX
  // Fields
  // Getters
  public get dataSampleSize(): number {
    return towerStore.currentDataSampleSize;
  }
  public set dataSampleSize(size: number) {
    towerStore.setCurrentDataSampleSize(size);
  }
  public mounted():void {
    this.initialDataSampleSize = this.dataSampleSize;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted():void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('chart-settings-changed')
  private chartSettingsChanged(actionEmit: string) {
    if (actionEmit === 'cancel') {
      towerStore.setCurrentDataSampleSize(this.initialDataSampleSize);
    }
  }
}
