import {
    Module,
    VuexModule,
    getModule,
    Mutation,
    Action,
  } from 'vuex-module-decorators';
import store from '@/store';
import { IEnvironmentVariables, IUser, IUserStore } from '@/store/types/user';
import { MeasurementSystemsEnum } from '@/enums';
import HelperMethods from '@/shared/helper-methods';
import UserService from '@/services/user-service';
@Module({
    namespaced: true,
    name: 'userStore',
    store,
    dynamic: true,
})
export class UserStore extends VuexModule implements IUserStore {
    // State
    public authUser: IUser | any = null;
    public currentMeasurementSystem: string = MeasurementSystemsEnum.DefaultUnits;
    public uomOptions: string[] = Object.assign([],
      HelperMethods.enumKeys(MeasurementSystemsEnum));
    public permissions: string[] = [];
    public env: IEnvironmentVariables = {
    baseApiUrl: 'http://localhost:5001/',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    googleMapsKey: '',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    intelligentDiagnosticsApiUrl: 'http://localhost:5001/',
    userManagementApiUrl: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'http://localhost:4501/',
    emaApiUrl: '',
    assetEditorApiUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    elasticApmServerUrl: '',
    elasticApmServiceName: '',
    carApiUrl: ''
  };
    // Getters
    public get hasPermission() : any {
    return (permission: string): boolean => {
      return this.permissions.includes(permission);
    };
  }
    // Mutations
  @Mutation
  public setCurrentMeasurementSystem(option: MeasurementSystemsEnum): void {
    this.currentMeasurementSystem = option;
  }
  @Mutation
  public setCurrentMeasurementSystemByCustomer(currentCustomerKey: string): void {
    const defaultIndex = this.uomOptions.indexOf(MeasurementSystemsEnum.DefaultUnits);
    if (defaultIndex > -1) {
      this.uomOptions.splice(defaultIndex, 1);
    }

    const index = this.uomOptions.indexOf(MeasurementSystemsEnum.PTTGCUnits);

    switch (currentCustomerKey) {
      case this.env.uomDashboardCustomerKey:
        this.currentMeasurementSystem = MeasurementSystemsEnum.PTTGCUnits;
        if (index === -1) {
          this.uomOptions.push(MeasurementSystemsEnum.PTTGCUnits);
        }
        break;
      default:
        if (index > -1) {
          this.uomOptions.splice(index, 1);
        }
        this.currentMeasurementSystem = MeasurementSystemsEnum.ImperialUnits;
        break;
    }
  }
  @Mutation
  public setUser(user: IUser) : void {
    this.authUser = user;
  }
  @Mutation
  public setPermissions(permissions: string[]): void{
    this.permissions = permissions;
  }
    @Action({ rawError: true })
    public async currentUser(): Promise<void> {
    try {
      const authUser = await UserService.createDefault().getUser();
      this.setUser(authUser);
    } catch (e) {
      throw e;
    }
  }

   @Action
  public async getPermissions(): Promise<void> {
    const permissions: string[] = await UserService.createDefault().getPermissions();
    this.setPermissions(permissions);
  }
}
let module: UserStore = getModule(UserStore, store);
export const setAppModule = (newModule: UserStore) => (module = newModule);
export default module;
