import { Nil } from './types';

export function ensureTrailing(val: string, trail: string) {
  return val != null && !val.endsWith(trail) ? `${val}${trail}` : val;
}

export function isStringEmpty(value: Nil<string>) {
  return value == null || value === '';
}
function hexDec4Chars() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}
const s4 = hexDec4Chars;
/**
 * Generates a random guid string
 * @returns {string}
 */
export function newGuid(): string {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
