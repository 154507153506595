




































































































































// Libraries
import { Component, Watch } from 'vue-property-decorator';
import { alphabeticSorter } from '@/shared/array-utils';
import { toCamelCase } from '@/shared/string-utils';
// import { globalAppCatch } from '@/shared/helper-methods';

// View Models
import {
  ITower,
  ITowerStage,
  ITowerStageEquipment,
  ITowerStageInput
} from '@/view-models';
import { ISelectItem } from '@/components/types/select-box';
import { TowerEquipmentTypeEnum } from '@/enums';
// Components
import RelativeTime from '@/components/common/RelativeTime.vue';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import StageEquipmentSection from '@/components/assets/tower/StageEquipmentSection.vue';
import TowerModal from '@/components/common/TowerModal.vue';
import ChartWidget from '@/components/assets/tower/widgets/ChartWidget.vue';
import DateTimePicker from '@/components/common/DateTimePicker.vue';
import SlideOutPanel from '@/components/assets/tower/SlideOutPanel.vue';
import TowerEditTimeSettings from '@/components/assets/tower/TowerEditTimeSettings.vue';
import TowerEditGlobalSettings from '@/components/assets/tower/TowerEditGlobalSettings.vue';
import TowerEditThresholdSettings from '@/components/assets/tower/TowerEditThresholdSettings.vue';
import TowerEditChartSettings from '@/components/assets/tower/TowerEditChartSettings.vue';
import StageInputSection from '@/components/assets/tower/widgets/StageInputSection.vue';
import towerStore from '@/store/towerStore';
import userStore from '@/store/userStore';
import Loading from '@/components/common/Loading.vue';
import { IDashboardTimeRangeSetting } from '@/view-models';
import { UnixEpochTime } from '@/view-models';
import TowerViewMixin from '@/components/mixins/TowerViewMixin';
import appStore from "@/store/appStore";
// Services
// Stores
@Component({
  name: 'tower-stage-view',
  components: {
    StageInputSection,
    TowerEditTimeSettings,
    RelativeTime,
    SelectDropdown,
    TowerModal,
    StageEquipmentSection,
    ChartWidget,
    DateTimePicker,
    SlideOutPanel,
    TowerEditGlobalSettings,
    TowerEditThresholdSettings,
    TowerEditChartSettings,
    Loading
  }
})
export default class TowerStageView extends TowerViewMixin {
  // VUE.JS Props
  // Properties
  private currentMeasurementSystemKey: string = 'cbd32687-763d-47f3-b9f4-b504b052d491';
  public filteredStageWidgetData: Array<ITowerStageEquipment> = this.stageWidgetData ? this.stageWidgetData : [];
  public filteredStageInputData: ITowerStageInput | any = this.stageInputWidgetData ? this.stageInputWidgetData : {};
  public selectedEquipmentType: any = null;
  public loadingData: boolean = false;
  public isPanelOpen: boolean = false;
  public showTowerGraphic: boolean = false;
  public showTimeControls: boolean = false;
  public showChartControls: boolean = false;
  public showActiveAlerts: boolean = false;
  public stageChanged: boolean = false;
  private initialTimeSetting: any = null;
  private initialTimeSelection: Array<UnixEpochTime> = [];
  private initialDataSampleSize: number = 0;
  public loading = true;
  // Getters
  public get tower(): ITower {
    return towerStore.currentTower;
  }
  public get towerTitle(): string {
    return `${this.tower?.name ?? ''} - Stage ${this.stage.order}`;
  }
  public get towerGraphic(): string {
    return towerStore?.currentTower?.imageSignedUrl;
  }
  public get stage(): ITowerStage {
    return towerStore.currentStage;
  }
  public get stageWidgetData(): ITowerStageEquipment[] | any {
    if(this.stage) {
    return this.stage.equipment;
    }
  }
  public get stageInputWidgetData(): ITowerStageInput | any {
    if(this.stage) {
    return this.stage.input;
    }
  }
  public get hasAlerts(): boolean {
    return (this.stage?.alerts?.length ?? 0) > 0;
  }
  public get lastTowerViewCalculation(): string {
    return towerStore?.currentTower?.lastTowerViewCalculation;
  }
  public get currentTimeSetting(): IDashboardTimeRangeSetting {
    return towerStore.currentTimeRange;
  }
  private get currentTimeSelection(): Array<UnixEpochTime> {
    return towerStore.currentTimeSelections;
  }
  public get dataSampleSize(): number {
    return towerStore.currentDataSampleSize;
  }
  public get stagesOptions(): ISelectItem<string>[] {
    // Remove stages with no equipment then sort by title
    const stages = this.tower?.stages;
    return stages.map(stage => ({
              text: this.$t('assets.tower.stages.nameTitle', { num: stage.order }).toString(),
              value: stage.stageKey
            }))
            .sort(alphabeticSorter(opt => opt.text)) ??
        [];
  }
  public get equipmentsOptions() {
    // Build the equipment options for dropdown based on available equipment
    const equipmentsOptions = [];
    equipmentsOptions.push({
      text: this.$t('assets.tower.stages.inputs'),
      value: TowerEquipmentTypeEnum.None
    });

    this.stageWidgetData.forEach((equipment: any) => {
      const equipmentNameType: string = equipment.name
          ? this.$t(`assets.tower.equipments.types.${toCamelCase(equipment.type)}${(equipment.name).split(' ').join('')}`).toString()
          : equipment.type;
      equipmentsOptions.push({
        text: equipmentNameType,
        value: equipmentNameType
      });
    });
    // Add in all equipment option if more than one equipment exists
    if (equipmentsOptions.length > 1) {
      equipmentsOptions.unshift({
        text: this.$t('assets.tower.equipments.types.allEquipment').toString(),
        value: TowerEquipmentTypeEnum.AllEquipment
      });
    }
    // Set initial dropdown display value
    this.selectedEquipmentType = equipmentsOptions[0].value;
    return equipmentsOptions;
  }
  public get timeRangeCacheKey(): string {
    return `${this.tower?.key ?? ''}-${this.stage?.stageKey ?? ''}`;
  }
  public get panelTitle(): string {
    if (this.showTowerGraphic) {
      return this.tower?.name;
    } else if (this.showTimeControls) {
      return 'Time Settings';
    } else if (this.showChartControls) {
      return 'Global Settings';
    } else {
      return '';
    }
  }
  public get panelIcon(): string {
    if (this.showTowerGraphic) {
      return 'mdi mdi-file-image-outline';
    } else if (this.showTimeControls) {
      return 'mdi mdi-clock';
    } else if (this.showChartControls) {
      return 'mdi mdi-chart-multiple';
    } else {
      return '';
    }
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  @Watch('$route')
  public routeChange() {
     towerStore.loadStageWidgetData().then(() =>  this.loading = false);
  }
  public get preferredMeasurementSystem() {
    return towerStore.preferredMeasurementSystem;
  }
  public async mounted(): Promise<void> {
    if (!this.tower){
      await towerStore.loadTower(appStore.assetKey);
      await towerStore.getUomOptions(userStore.authUser?.activeCustomerKey);
    }
    if(!this.stage){
      towerStore.setStage(this.$route.params.key);
    }
    await this.updateData();
    this.isPanelOpen = false;
    this.loading = false;
  }
  public async onStageSelected(stageKey: string) {
    towerStore.setStage(stageKey);
    await this.$router.replace(`/${stageKey}`);
    this.loading = true;
    this.filteredStageWidgetData = this.stageWidgetData;
    this.filteredStageInputData = this.stageInputWidgetData;
    this.stageChanged = true;
  }
  public onEquipmentSelected(): void {
    if (this.selectedEquipmentType === TowerEquipmentTypeEnum.AllEquipment) {
      this.filteredStageWidgetData = this.stageWidgetData;
      this.filteredStageInputData = this.stageInputWidgetData;
    } else if (this.selectedEquipmentType === TowerEquipmentTypeEnum.None) {
      this.filteredStageWidgetData = [];
      this.filteredStageInputData = this.stageInputWidgetData;
    } else {
      this.filteredStageWidgetData = this.stageWidgetData.filter((equipment: ITowerStageEquipment | any) => {
        if (equipment.hasOwnProperty('name')) {
          const equipmentName: string = equipment.type
              ? this.$t(`assets.tower.equipments.types.${toCamelCase(equipment.type)}${(equipment.name).split(' ').join('')}`).toString()
              : '';
          if (this.selectedEquipmentType === equipmentName) {
            return equipment;
          }
        } else if (this.selectedEquipmentType === equipment.type) {
          return equipment;
        }
      });
      this.filteredStageInputData = null;
    }
    this.stageChanged = false;
  }
  public toggleCollapse(): void {
    this.showActiveAlerts = !this.showActiveAlerts;
  }
  public togglePanel(toShow: string): void {
    switch (toShow) {
      case 'image':
        this.showTimeControls = false;
        this.showChartControls = false;
        this.showTowerGraphic = true;
        this.isPanelOpen = true;
        break;
      case 'time':
        this.showChartControls = false;
        this.showTowerGraphic = false;
        this.showTimeControls = true;
        this.isPanelOpen = true;
        break;
      case 'chart':
        this.showTimeControls = false;
        this.showTowerGraphic = false;
        this.showChartControls = true;
        this.isPanelOpen = true;
        break;
      default:
        this.showTimeControls = false;
        this.showChartControls = false;
        this.showTowerGraphic = false;
        this.isPanelOpen = false;
        towerStore.setTimeRange(this.initialTimeSetting);
        towerStore.setCurrentTimeSelections(this.initialTimeSelection);
        towerStore.setCurrentDataSampleSize(this.initialDataSampleSize);
    }
  }
  public updateWidgets(action: string) {
    if (action === 'update') {
      this.updateData();
    } else {
      this.togglePanel('none');
    }
  }
  public async updateData(): Promise<void> {
    this.loadingData = true;
    this.isPanelOpen = !this.isPanelOpen;
    await towerStore.loadStageWidgetData(this.currentMeasurementSystemKey);
    this.filteredStageWidgetData = this.stageWidgetData;
    this.filteredStageInputData = this.stageInputWidgetData;
    this.loadingData = false;
    this.initialTimeSetting = Object.assign(this.currentTimeSetting, {});
    this.initialTimeSelection = Object.assign(this.currentTimeSelection, {});
    this.initialDataSampleSize = this.dataSampleSize;
  }

  public onTowerStageMeasurementSystemChange(option: string): void {
    const fromUomName = this.updatedUomOptions?.filter((uom: any) => uom.value === this.currentMeasurementSystemKey)[0].text
        .split(' ')[0];
    const toUomName = this.updatedUomOptions.filter((uom: any) => uom.value === option)[0].text;
    this.toastMessage(this.$t('dashboard.toast.threshold.message',
        { fromUomName: `${fromUomName}`, toUomName: `${toUomName}` }).toString(),
        this.$t('dashboard.toast.threshold.header').toString());
    this.setCurrentMeasurementSystemKey(option);
    this.updateData();
    this.isPanelOpen = false;
  }

  private setCurrentMeasurementSystemKey(key: string) {
    this.currentMeasurementSystemKey = key;
  }
  // Watchers
  // Emitters
}
