// Libraries
import { Component } from 'vue-property-decorator';
// View Models
import { MeasurementSystemsEnum, TowerInputTypesEnum, TowerModalTypesEnum } from '@/enums';
// Components
import { ISelectItem } from '@/components/types/select-box';
import { IAvailableMeasurementSystemsViewModel, IEquipmentIndicator, IStageInputIndicator, ITowerValidation } from '@/view-models';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import { toCamelCase } from '@/shared/string-utils';
import BaseComponent from '@/components/shared/BaseComponent.vue';
import userStore from '@/store/userStore';
import towerStore from "@/store/towerStore";
// Services
// Store

@Component({
  name: 'tower-view-mixin',
  components: {
    SelectDropdown
  }
})
export default class TowerViewMixin extends BaseComponent {
  // VUE.JS Props
  // VUEX
  // Properties
  public indicator: IEquipmentIndicator | IStageInputIndicator | ITowerValidation | any = null
  public isModalOpen: boolean = false;
  public showThresholdModal: boolean = false;
  public showChartSettingModal: boolean = false;
  public showFullScreenModal: boolean = false;
  // Fields
  // Getters
  public get currentMeasurementSystem(): string {
   return userStore.currentMeasurementSystem;
  }

  public get preferredMeasurementSystem(): string {
    return towerStore.preferredMeasurementSystem;
  }

  public get currentCustomerKey() {
    return userStore?.authUser?.activeCustomerKey;
  }

  public get uomOptions(): IAvailableMeasurementSystemsViewModel[] {
    return towerStore.uomOptions;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  public created(): void {
    // userStore.setCurrentMeasurementSystemByCustomer(this.currentCustomerKey);
    // this.$vxm.dashboard.setCurrentMeasurementSystem(this.currentMeasurementSystem);
    // this.$vxm.dashboard.updateDashboardWidgetsUoM();
  }
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public showModal(indicator: IEquipmentIndicator | IStageInputIndicator): void {
    // Data is coming from the widget container component
    this.indicator = indicator;
    switch (indicator.toShow) {
      case TowerModalTypesEnum.Fullscreen:
        this.showFullScreenModal = true;
        this.isModalOpen = !this.isModalOpen;
        break;
      case TowerModalTypesEnum.Thresholds:
        this.showThresholdModal = true;
        this.isModalOpen = !this.isModalOpen;
        break;
      case TowerModalTypesEnum.ChartSettings:
        this.showChartSettingModal = true;
        this.isModalOpen = !this.isModalOpen;
        break;
    }
  }

  public hideModal(value : boolean): void {
    this.isModalOpen = value;
    if (!this.isModalOpen) {
      setTimeout(() => {
        this.showThresholdModal = false;
        this.showFullScreenModal = false;
        this.showChartSettingModal = false;
      }, 500);
    }
  }
  public get indicatorFullTitle(): string {
    if (this.indicator?.type === TowerInputTypesEnum.TowerEquipment) {
      return (this.indicator.equipmentType && this.indicator.displayValue)
          ? `${this.$t(`assets.tower.equipments.types.${toCamelCase(this.indicator.equipmentType)}`)}
           - ${this.$t(`assets.tower.displayValues.${toCamelCase(this.indicator.displayValue)}`)}`
          : '';
    }
    if (this.indicator?.type === TowerInputTypesEnum.TowerStageInput) {
      return (this.indicator.displayValue)
          ? `${this.$t('assets.tower.stages.inputs')}
           - ${this.$t(`assets.tower.displayValues.${toCamelCase(this.indicator.displayValue)}`)}`
          : '';
    }
    return (this.indicator?.displayValue)
        ? `${this.$t(`assets.tower.displayValues.${toCamelCase(this.indicator.displayValue)}`)}`
        : '';
  }
  public get modalTitle(): string {
    if (this.showThresholdModal) {
      return this.$t(`assets.tower.stages.thresholds.title`).toString();
    } else if (this.showFullScreenModal) {
      return this.indicatorFullTitle;
    } else if (this.showChartSettingModal) {
      return this.$t(`assets.tower.stages.chartSettings.title`).toString();
    } else return '';
  }
  public get modalSubtitle() {
    if (this.showThresholdModal || this.showChartSettingModal) {
      return this.indicatorFullTitle;
    }
  }
  public get modalSize(): string {
    return (this.indicator?.toShow === TowerModalTypesEnum.Fullscreen) ? 'xl' : 'lg';
  }

  public get updatedUomOptions(): Array<ISelectItem<MeasurementSystemsEnum>> | any {
    const values: Array<ISelectItem<MeasurementSystemsEnum>> = [];
     this.uomOptions.forEach((option: any) => {
      values.push({
        text: this.$t(`dashboard.measurementSystems.${option.systemName}`).toString(),
        value: option.systemKey
      });
    });
    return values;
  }

  // Event Methods
  // Watchers
  // Emitters
}
