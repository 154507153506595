import VueI18n from 'vue-i18n';
import { getI18N } from '@/utils/i18n';
import {
  EquipmentIndicatorDisplayValuesEnum,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentTypeEnum,
  TowerIndicatorDisplayValuesEnum,
  TowerInputTypesEnum,
  TowerUnitOfMeasurementsEnum
} from '@/enums';
import { IDisplayWidgetInput, IWidgetValueFormatters } from '@/view-models/widgets';
import WidgetSeriesColors from '@/shared/widget-series-colors';
import { IEquipmentIndicatorRequest, ITowerStageEquipment } from '@/view-models/stages/tower-view-models';
import { INumericUnitConfigTable, NumericUnitFormatter } from './formatter';
/*
Legend
  - Get Graph Color By Unit Of Measurement: unitOfMeasurementsMapColorSeries
  - Get Unit Of Measurement By Display Value: displayValuesMapUoms
  - Get getValueYWithFixedDecimalPoint
  - Get Indicators For Each Equipment: equipmentIndicatorsMap
*/

/*
Determines what line color should be presented for graph visualizations in tower view based on the unit of measurement.
Change the index number in the getColor function to swap colors out.
Colors are defined in the WidgetSeriesColors file.
*/
const unitOfMeasurementsMapColorSeries: {[type in TowerUnitOfMeasurementsEnum]: WidgetSeriesColors} = {
  [TowerUnitOfMeasurementsEnum.velocity]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.mbarM]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.SiFFactor]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.meters]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.CubicMetersPerSecond]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.mmHg]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.cubicMeters]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.celsius]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.seconds]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.millimeters]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.empty]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.none]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.percentage]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.kilogramsPerCubicMeter]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.kilogramsPerSecond]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.newtonsPerMeter]: WidgetSeriesColors.getColor(4),
  [TowerUnitOfMeasurementsEnum.pascalSeconds]: WidgetSeriesColors.getColor(4)
};
export function getSeriesColorByUom(uom: TowerUnitOfMeasurementsEnum) {
  return unitOfMeasurementsMapColorSeries[uom] ?? WidgetSeriesColors.getColor(4);
}

/* Determines the unit of measurement for each equipment indicator, defaults to none if no mapping present */
const equipmentIndicatorDisplayValuesMapUoms: {[type in EquipmentIndicatorDisplayValuesEnum]: TowerUnitOfMeasurementsEnum} = {
  [EquipmentIndicatorDisplayValuesEnum.AerationFlood]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.CFactor]: TowerUnitOfMeasurementsEnum.velocity,
  [EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodAverage]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodMax]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.DowncomerFloodAverage]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.DowncomerFloodMax]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.DP]: TowerUnitOfMeasurementsEnum.mbarM,
  [EquipmentIndicatorDisplayValuesEnum.Entrainment]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.ExitVelocity]: TowerUnitOfMeasurementsEnum.velocity,
  [EquipmentIndicatorDisplayValuesEnum.FFactor]: TowerUnitOfMeasurementsEnum.SiFFactor,
  [EquipmentIndicatorDisplayValuesEnum.Flood]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.FloodMax]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.FloodConstantLiquid]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.FloodConstantLiquidVapor]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.JetFlood]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.JetFloodAverage]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.JetFloodMax]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.LiquidHeight]: TowerUnitOfMeasurementsEnum.meters,
  [EquipmentIndicatorDisplayValuesEnum.LiquidHoldup]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.LiquidLoad]: TowerUnitOfMeasurementsEnum.CubicMetersPerSecond,
  [EquipmentIndicatorDisplayValuesEnum.PctApproachToOverflow]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.ResidenceTime]: TowerUnitOfMeasurementsEnum.seconds,
  [EquipmentIndicatorDisplayValuesEnum.TotalDp]: TowerUnitOfMeasurementsEnum.mmHg,
  [EquipmentIndicatorDisplayValuesEnum.TotalDpvsh]: TowerUnitOfMeasurementsEnum.mmHg,
  [EquipmentIndicatorDisplayValuesEnum.Weepage]: TowerUnitOfMeasurementsEnum.percentage,
  [EquipmentIndicatorDisplayValuesEnum.WeirLoadSub]: TowerUnitOfMeasurementsEnum.cubicMeters,
  [EquipmentIndicatorDisplayValuesEnum.WeirLoadTop]: TowerUnitOfMeasurementsEnum.cubicMeters
};
const towerDisplayValuesMapUoms: {[type in TowerIndicatorDisplayValuesEnum]: TowerUnitOfMeasurementsEnum} = {
  [TowerIndicatorDisplayValuesEnum.TotalDP]: TowerUnitOfMeasurementsEnum.mmHg
};
const stageInputDisplayValuesMapUoms: {[type in StageInputIndicatorDisplayValuesEnum]: TowerUnitOfMeasurementsEnum} = {
  [StageInputIndicatorDisplayValuesEnum.LiquidMassRate]: TowerUnitOfMeasurementsEnum.kilogramsPerSecond,
  [StageInputIndicatorDisplayValuesEnum.LiquidDensity]: TowerUnitOfMeasurementsEnum.kilogramsPerCubicMeter,
  [StageInputIndicatorDisplayValuesEnum.LiquidSurfaceTension]: TowerUnitOfMeasurementsEnum.newtonsPerMeter,
  [StageInputIndicatorDisplayValuesEnum.LiquidViscosity]: TowerUnitOfMeasurementsEnum.pascalSeconds,
  [StageInputIndicatorDisplayValuesEnum.VaporMassRate]: TowerUnitOfMeasurementsEnum.kilogramsPerSecond,
  [StageInputIndicatorDisplayValuesEnum.VaporDensity]: TowerUnitOfMeasurementsEnum.kilogramsPerCubicMeter,
  [StageInputIndicatorDisplayValuesEnum.VaporViscosity]: TowerUnitOfMeasurementsEnum.pascalSeconds
};

export function getUomByDisplayValue(displayValue: any, type: TowerInputTypesEnum) {
  switch (type) {
    // case TowerInputTypesEnum.Tower:
    //   return towerDisplayValuesMapUoms[displayValue] ?? TowerUnitOfMeasurementsEnum.empty;
    // case TowerInputTypesEnum.TowerEquipment:
    //   return equipmentIndicatorDisplayValuesMapUoms[displayValue] ?? TowerUnitOfMeasurementsEnum.empty;
    // case TowerInputTypesEnum.TowerStageInput:
    //   return stageInputDisplayValuesMapUoms[displayValue] ?? TowerUnitOfMeasurementsEnum.empty;
    default:
    return TowerUnitOfMeasurementsEnum.empty;
  }
}

export function getValueYWithFixedDecimalPoint(value: number, removeTrailingZeroes?: boolean): string {
  const absoluteValue = Math.abs(value);
  if (absoluteValue < 1 && absoluteValue > 0) {
    if (absoluteValue < 0.001) {
      return removeTrailingZeroes ? `${value.toExponential(2)}`.toUpperCase() : `${value.toExponential(2)}`.toUpperCase();
    }
    return removeTrailingZeroes ? `${+value.toFixed(4)}` : `${value.toFixed(4)}`;
  }
  return removeTrailingZeroes ? `${+value.toFixed(2)}` : `${value.toFixed(2)}`;
}

/* Map showing the types of equipment indicators that are attached to each piece of equipment */
export const equipmentIndicatorsMap: any = {
  [TowerEquipmentTypeEnum.ChimneyCollector]: [
    EquipmentIndicatorDisplayValuesEnum.LiquidHeight,
    EquipmentIndicatorDisplayValuesEnum.PctApproachToOverflow
  ],
  [TowerEquipmentTypeEnum.PackingBed]: [
    EquipmentIndicatorDisplayValuesEnum.FloodConstantLiquid,
    EquipmentIndicatorDisplayValuesEnum.FloodConstantLiquidVapor,
    EquipmentIndicatorDisplayValuesEnum.LiquidHoldup,
    EquipmentIndicatorDisplayValuesEnum.FFactor,
    EquipmentIndicatorDisplayValuesEnum.CFactor,
    EquipmentIndicatorDisplayValuesEnum.LiquidLoad,
    EquipmentIndicatorDisplayValuesEnum.DP
  ],
  [TowerEquipmentTypeEnum.PartingBox]: [
    EquipmentIndicatorDisplayValuesEnum.LiquidHeight,
    EquipmentIndicatorDisplayValuesEnum.PctApproachToOverflow
  ],
  [TowerEquipmentTypeEnum.Tray]: [
    EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodMax,
    EquipmentIndicatorDisplayValuesEnum.DowncomerFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.DowncomerFloodMax,
    EquipmentIndicatorDisplayValuesEnum.JetFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.JetFloodMax,
    EquipmentIndicatorDisplayValuesEnum.TotalDpvsh,
    EquipmentIndicatorDisplayValuesEnum.TotalDp
  ],
  [TowerEquipmentTypeEnum.SumpTray]: [
    EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.DowncomerAerationFloodMax,
    EquipmentIndicatorDisplayValuesEnum.DowncomerFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.DowncomerFloodMax,
    EquipmentIndicatorDisplayValuesEnum.JetFloodAverage,
    EquipmentIndicatorDisplayValuesEnum.JetFloodMax,
    EquipmentIndicatorDisplayValuesEnum.TotalDpvsh,
    EquipmentIndicatorDisplayValuesEnum.TotalDp
  ],
  [TowerEquipmentTypeEnum.TroughDistributor]: [
    EquipmentIndicatorDisplayValuesEnum.LiquidHeight,
    EquipmentIndicatorDisplayValuesEnum.PctApproachToOverflow
  ],
  [TowerEquipmentTypeEnum.Downcomer]: [
    EquipmentIndicatorDisplayValuesEnum.AerationFlood,
    EquipmentIndicatorDisplayValuesEnum.Flood,
    EquipmentIndicatorDisplayValuesEnum.ExitVelocity,
    EquipmentIndicatorDisplayValuesEnum.ResidenceTime,
    EquipmentIndicatorDisplayValuesEnum.WeirLoadSub,
    EquipmentIndicatorDisplayValuesEnum.WeirLoadTop
  ],
  [TowerEquipmentTypeEnum.Panel]: [
    EquipmentIndicatorDisplayValuesEnum.Entrainment,
    EquipmentIndicatorDisplayValuesEnum.JetFlood,
    EquipmentIndicatorDisplayValuesEnum.Weepage
  ],
  [TowerEquipmentTypeEnum.None]: [],
  [TowerEquipmentTypeEnum.AllEquipment]: []
};
export function createEquipmentIndicator(equipment: ITowerStageEquipment, displayValue: EquipmentIndicatorDisplayValuesEnum): IEquipmentIndicatorRequest {
  return {
    equipmentKey: equipment.equipmentKey,
    type: TowerInputTypesEnum.TowerEquipment,
    equipmentType: equipment.type,
    equipmentName: (equipment.name ?? null),
    displayValue
  };
}

// below possibly deprecated
const defaultsUnitConfigsTable: INumericUnitConfigTable = {
  [TowerUnitOfMeasurementsEnum.percentage]: {
    unitTranslation: 'assets.tower.uoms.percentage',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.mmHg]: {
    unitTranslation: 'assets.tower.uoms.mmHg',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.celsius]: {
    unitTranslation: 'assets.tower.uoms.celsius',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.SiFFactor]: {
    unitTranslation: 'assets.tower.uoms.SiFFactor',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.CubicMetersPerSecond]: {
    unitTranslation: 'assets.tower.uoms.CubicMetersPerSecond',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.mbarM]: {
    unitTranslation: 'assets.tower.uoms.mbarM',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.meters]: {
    unitTranslation: 'assets.tower.uoms.meters',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.velocity]: {
    unitTranslation: 'assets.tower.uoms.meters',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.seconds]: {
    unitTranslation: 'assets.tower.uoms.percentage',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.cubicMeters]: {
    unitTranslation: 'assets.tower.uoms.percentage',
    maxDecimalsLength: 2,
    abbreviate: false
  },
  [TowerUnitOfMeasurementsEnum.millimeters]: {
    unitTranslation: 'assets.tower.uoms.percentage',
    maxDecimalsLength: 2,
    abbreviate: false
  }
};
export function buildTowerWidgetFormatters(i18n?: VueI18n): IWidgetValueFormatters {
  const formatter = new NumericUnitFormatter(i18n ?? getI18N(), defaultsUnitConfigsTable);

  return {
    yValue(input: IDisplayWidgetInput, value: number): string {
      return formatter.formatWithTable(input.unitOfMeasurement, value);
    },
    yValueTooltip(input: IDisplayWidgetInput, value: number): string {
      return formatter.formatWithTable(input.unitOfMeasurement, value, { abbreviate: false });
    }
  };
}

export function isStageType(type: TowerInputTypesEnum): boolean {
  return type === TowerInputTypesEnum.TowerEquipment || type === TowerInputTypesEnum.TowerStageInput;
}
