



























































// Libraries
import { Component, Emit, Prop } from 'vue-property-decorator';
// View Models

// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import { IDashboardTimeRangeSetting } from '@/view-models';
import { ISelectItem } from '@/components/types/select-box';
import { DashboardTimeRangesEnum, SkipDirectionsEnum } from '@/enums';
import { asDate, asUnixTimestamp } from '@/shared/date-time-utils';
import { buildDashboardDateRange, skipDateRange } from '@/shared/time-helper-methods';
import TimeRangeSkipper from '@/components/common/TimeRangeSkipper.vue';
import { IDateRangeObjects } from '@/view-models/report-time-model';
import DateTimePicker from '@/components/common/DateTimePicker.vue';
import towerStore from '@/store/towerStore';
import { UnixEpochTime } from '@/view-models';

@Component({
  name: 'tower-edit-time-settings',
  components: {
    TimeRangeSkipper,
    DateTimePicker
  }
})
export default class TowerEditTimeSettings extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public readonly cacheKey!: string;
  private readonly skipUpdate = true;
  private isRangeChange = false;
  private customChangeCount = 0;
  // Properties
  public initialTimeSetting: IDashboardTimeRangeSetting | any = null;
  private initialTimeSelection: Array<UnixEpochTime> = [];
  // VUEX
  // Fields
  // Getters
  private get currentTimeSelection(): Array<UnixEpochTime> {
    return towerStore.currentTimeSelections;
  }
  public get currentTimeSetting(): IDashboardTimeRangeSetting {
    return towerStore.currentTimeRange;
  }
  public set currentTimeSetting(value: IDashboardTimeRangeSetting) {
    towerStore.setTimeRange(value);
  }
  public get rangeStart(): Date {
    return this.currentTimeSetting?.range?.fromDate;
  }
  public get rangeEnd(): Date {
    return this.currentTimeSetting?.range?.toDate;
  }
  public get timeRangeOptions(): ISelectItem<string>[] {
    return Object.values(DashboardTimeRangesEnum).map(type => ({
      text: this.$t(`dashboard.timeRanges.${type}`).toString(),
      value: type
    }));
  }
  public get timelinePoints(): Array<Date | any> {
    return towerStore.currentTimeSelections.map((times) => {
      return asDate(times);
    });
  }
  public set timelinePoints(selections: Array<Date | any>) {
    const arr = selections.map((time: any) => {
      return (asUnixTimestamp(time));
    });
    towerStore.setCurrentTimeSelections(arr);
  }
  public addTimeSelection() {
    this.timelinePoints.push(this.rangeEnd);
      const selections = this.timelinePoints.map((item) => {
        return (asUnixTimestamp(item));
      });
      towerStore.setCurrentTimeSelections(selections);
  }
  public setSelectedTime(valEmit : any, index: number) {
    let selections = [];
    if (valEmit instanceof Date && !isNaN(valEmit.valueOf())) {
      // Changing dates
      this.timelinePoints.splice(index, 1, valEmit);
       selections = this.timelinePoints.map((item) => {
        return (asUnixTimestamp(item));
      });

      towerStore.setCurrentTimeSelections(selections);
    } else if (valEmit === null) {
      // Deleting date
      this.timelinePoints.splice(index, 1);
      selections = this.timelinePoints.map((item) => {
        return (asUnixTimestamp(item));
      });
      towerStore.setCurrentTimeSelections(selections);
    } else {
      // Set all dates to latest time end range
      const selections = (this.timelinePoints ?? []).map(unused => (asUnixTimestamp(this.rangeEnd)));
      towerStore.setCurrentTimeSelections(selections);
    }
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted():void {
    // Create an initial reference that can be used to restore a previous time selection.
    this.initialTimeSetting = Object.assign(this.currentTimeSetting, {});
    this.initialTimeSelection = Object.assign(this.currentTimeSelection, {});
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public setRange(type: any): void {
    this.isRangeChange = true;
    const newRange: IDashboardTimeRangeSetting = {
      type,
      range: buildDashboardDateRange(type)
    };
    towerStore.setTimeRange(newRange);
  }
  // Event Methods
  public onSkip(direction: SkipDirectionsEnum) {
    const range = skipDateRange(this.currentTimeSetting?.range, direction);
    const type = this.currentTimeSetting.type;
    const newRange: IDashboardTimeRangeSetting = { type, range };
    towerStore.setTimeRange(newRange);
  }
  public onCustomChange(range: IDateRangeObjects) {
    this.customChangeCount++;
    const type = this.isRangeChange ? this.currentTimeSetting.type : DashboardTimeRangesEnum.Custom;
    const newRange: IDashboardTimeRangeSetting = { type, range };
    towerStore.setTimeRange(newRange);
    if ((this.isRangeChange && this.customChangeCount === 2) || (!this.isRangeChange && this.customChangeCount > 0)) {
      this.isRangeChange = false;
      this.customChangeCount = 0;
    }
  }
  // Watchers
  // Emitters
  @Emit('time-settings-changed')
  private timeSettingsChanged(action: string) {
    if (action === 'cancel') {
      towerStore.setTimeRange(this.initialTimeSetting);
      towerStore.setCurrentTimeSelections(this.initialTimeSelection);
    }
    return action;
  }
}
