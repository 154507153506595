import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule
  } from 'vuex-module-decorators';
import TowerService from '@/services/tower-service';
import {
  ICustomerPreferenceService,
  IEquipmentIndicatorResponse,
  IStageInputIndicatorResponse,
  ITowerService,
  ITowerThreshold,
  StageInputIndicator,
  StageInputIndicatorRequest,
  UnixEpochTime
} from '@/view-models';
import { LocalStorageByUser } from '@/shared/storage-utils';
import { toCamelCase } from '@/shared/string-utils';
import { createEquipmentIndicator, equipmentIndicatorsMap, getUomByDisplayValue } from '@/shared/tower-utils';
import {
  ChartTypesEnum,
  DashboardTimeRangesEnum,
  ReportAxis,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentNameEnum,
  TowerEquipmentTypeEnum,
  TowerInputTypesEnum,
  TowerStageFilterOptionEnum,
  WidgetTypes
} from '@/enums';
import { IDashboardTimeRangeSetting } from '@/view-models';
import axios from 'axios';
import { ITowerStore } from '@/store/types/tower';
import { asServerDateTime, dateObjectsToDateRange } from '@/view-models/report-time-model';
import { getI18N } from '@/utils/i18n';
import {
  EquipmentIndicator,
  EquipmentIndicatorRequest,
  IObjectPropertyUpdate,
  IStageWidgetDataRequest,
  IStageWidgetDataResponse,
  ITower,
  ITowerAlertHistoryRequest,
  ITowerCustomThresholdRequest,
  ITowerHistoricalAlert,
  ITowerIndicator,
  ITowerStage,
  ITowerStageStorageModel,
  ITowerThresholdsResponse,
  ITowerValidation,
  ITowerWidgetDataRequest,
  ITowerWidgetDataResponse,
  IValidationsWidgetDataRequest,
  IValidationsWidgetDataResponse,
  TowerIndicator,
  TowerValidation
} from '@/view-models';
import { cloneDeep, set, has, partition } from 'lodash';
import store from '@/store';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';
import CustomerPreferenceService from '@/services/customer-preference-service';
@Module({
    namespaced: true,
    name: 'towerStore',
    store,
    dynamic: true,
})

export class TowerStore extends VuexModule implements ITowerStore {
  // State
  public currentTower: ITower | any = null;
  public currentStage: ITowerStage | any = null;
  public currentTimeRange: IDashboardTimeRangeSetting | any = {
    type: DashboardTimeRangesEnum.OneWeek,
    range: {
      fromDate: new Date(Math.abs(new Date().getTime()) - (7 * 86400000)), // Today - (7 * Total seconds in day) = 1 Week
      toDate: new Date() // Today
    }
  };
  public currentTimeSelections: Array<UnixEpochTime> = [];
  public currentDataSampleSize: number = 400;
  public currentAlertHistory: Array<ITowerHistoricalAlert> = [];
  public towerThresholdsMap:  Map<string, Array<ITowerThreshold>> | any  = null;  // Map<string, Array<ITowerThreshold>>
  public towerStageFilter: TowerStageFilterOptionEnum = TowerStageFilterOptionEnum.All;
  public preferredMeasurementSystem: string = '';
  public uomOptions: IAvailableMeasurementSystemsViewModel[] = [];
  // Getters
  public get currentValidationsWidgetData(): Array<ITowerValidation> {
    if (this.currentTower?.validations?.length > 0 && this.currentTower?.validations[0].hasOwnProperty('chartSettings')) {
      return this.currentTower.validations;
    }
    return [];
  }
  public get currentTowerIndicators(): Array<ITowerIndicator> {
    if (this.currentTower?.indicators?.length > 0 && this.currentTower?.indicators[0].hasOwnProperty('chartSettings')) {
      return this.currentTower.indicators;
    }
     return [];
  }

  // Internal
  protected get towerService(): ITowerService {
    return new TowerService(axios);
  }
  protected get customerPreferenceService(): ICustomerPreferenceService {
    return new CustomerPreferenceService(axios);
  }
  private tableFilterStorage = new LocalStorageByUser<TowerStageFilterOptionEnum>('tower-summary-stages-filter');
  private towerStageStorage = new LocalStorageByUser<ITowerStageStorageModel>('tower-stages-storage');
  // Mutations
  @Mutation
  public setTower(tower: ITower): void {
    // Update the tower object to include performance indicators for each piece of equipment
    tower.stages = (tower.stages ?? []).map((stage : any) => {
      stage.equipment = (stage?.equipment ?? []).map((equipment : any) => {
        equipment.indicators = (equipmentIndicatorsMap[equipment.type] ?? [])
          .map((displayValue : any) => createEquipmentIndicator(equipment, displayValue));
        return equipment;
    });
    stage.input = {
      indicators: Object.values(StageInputIndicatorDisplayValuesEnum).map((displayValue) => {
        return {
          displayValue,
          equipmentType: TowerEquipmentTypeEnum.None,
          type: TowerInputTypesEnum.TowerStageInput
        };
      })
    };
      return stage;
    });

    // Create formatted alert display text
    const i18n = getI18N();
    for (const stage of tower.stages as any) {
      stage.alerts = stage.alerts.map((alert : any) => {
        const equipmentType = alert.equipmentType
            ? i18n.t(`assets.tower.equipments.types.${toCamelCase(alert.equipmentType)}`).toString()
            : '';
        const displayValue = alert.displayValue
            ? i18n.t(`assets.tower.displayValues.${toCamelCase(alert.displayValue)}`).toString()
            : '';
        const value = alert.value.toFixed(2).toString();
        if (alert.equipmentName === TowerEquipmentNameEnum.Inputs) {
          alert.formattedDisplayValue = i18n.t('assets.tower.stages.input') + ': ' + displayValue + ' - ' + value;
        } else if (alert.equipmentName) {
          const equipmentName = (alert.equipmentType || alert.equipmentName)
              ? i18n.t(`assets.tower.equipments.types.${toCamelCase(alert.equipmentType + alert.equipmentName)}`).toString()
              : '';
          alert.formattedDisplayValue = equipmentName + ': ' + displayValue + ' - ' + value;
        } else {
          alert.formattedDisplayValue = equipmentType + ': ' + displayValue + ' - ' + value;
        }
        return alert;
      });
    }
    // Save the modified tower object to the store
    this.currentTower = tower;
  }
  @Mutation
  public setUomOptions(options: IAvailableMeasurementSystemsViewModel[]) {
    this.uomOptions = Object.assign([], options);
  }
  @Mutation
  public setPreferredMeasurementSystem(option: string): void {
    this.preferredMeasurementSystem = option;
  }
  @Mutation
  public setStage(stageKey: string): void {
    this.currentStage = this.currentTower?.stages.find((stage : any) => stage.stageKey === stageKey);
  }
  @Mutation
  public setStageWidgetData(response: IStageWidgetDataResponse): void {
    if (response) {
      // Reset the store to the active stage
      this.currentStage = this.currentTower?.stages.find((stage : any) => stage.stageKey === response.stageKey);

      // Create alerts Set
      const alertsSet = new Set();
      if (this.currentStage && this.currentStage.alerts?.length > 0) {
        for (const alert of this.currentStage.alerts) {
          alertsSet.add(alert.equipmentType.toLowerCase() + alert.displayValue.toLowerCase());
        }
      }

      // Separate equipment data from stage input data
      const [equipmentResponses, stageInputResponses] = partition(response?.inputs, { type: TowerInputTypesEnum.TowerEquipment });

      // Format response to front end friendly interface
      const equipmentIndicators = equipmentResponses.map((response : any) => new EquipmentIndicator(response as IEquipmentIndicatorResponse));
      const stageInputIndicators = stageInputResponses.map((response : any) => new StageInputIndicator(response as IStageInputIndicatorResponse));

      const setIndicatorSettings = (indicator: EquipmentIndicator | StageInputIndicator): void => {
        indicator.chartSettings = {
          chartType: ChartTypesEnum.Line,
          widgetType: WidgetTypes.Chart,
          axis: ReportAxis.Left,
          sortOrder: 0,
          isFullScreen: false,
          scale: { min: null, max: null }
        };
        indicator.chartSettings.showTitleAtBottom = indicator.chartSettings.widgetType === WidgetTypes.Chart;
        // indicator.uom = getUomByDisplayValue(indicator.displayValue, indicator.type as any);

        // Add in thresholds
        const key = (indicator.type === TowerInputTypesEnum.TowerEquipment)
            ? `${this.currentStage.stageKey}:${(indicator as EquipmentIndicator).equipmentKey}:${indicator.displayValue}`
            : `${this.currentStage.stageKey}:${indicator.displayValue}`;
        if (this.towerThresholdsMap.has(key)) {
          indicator.thresholds = this.towerThresholdsMap.get(key);
        }

        // Determine if indicator has alerts
        indicator.inWarningLevel = alertsSet.has(indicator.equipmentType.toLowerCase() + indicator.displayValue.toLowerCase());
        indicator.stageOrder = this.currentStage.order;
      };

      const equipmentIndicatorMap = new Map();
      for (const indicator of equipmentIndicators) {
        setIndicatorSettings(indicator);
        equipmentIndicatorMap.set(indicator.key, indicator);
      }

      const stageInputIndicatorMap = new Map();
      for (const indicator of stageInputIndicators) {
        setIndicatorSettings(indicator);
        stageInputIndicatorMap.set(indicator.key, indicator);
      }

      // Match updated equipment performance indicator to current stage equipment
      this.currentStage.equipment = this.currentStage.equipment.map((equipment : any) => {
        equipment.indicators = equipment.indicators.map((indicator: any) => {
          const id = `${indicator.equipmentKey}:${indicator.displayValue}`;
          if (equipmentIndicatorMap.has(id)) {
            return equipmentIndicatorMap.get(id);
          }
        });
        return equipment;
      });

      // Match updated stage input performance indicator to current stage input
      this.currentStage.input.indicators = this.currentStage.input.indicators?.map((indicator: any) => {
        const id = `${indicator.displayValue}`;
        if (stageInputIndicatorMap.has(id)) {
          return stageInputIndicatorMap.get(id);
        }
      });
    }
  }
  @Mutation
  public setTowerWidgetData(response: ITowerWidgetDataResponse): void {
    if (response) {
      if (response.towerKey === this.currentTower.key) {
        // Format response to front end friendly interface
        const towerIndicators = response.inputs.map((input : any) => {
            return new TowerIndicator(input, response.towerKey);
        });

        for (const indicator of towerIndicators) {
          // Build each performance indicator and prepare data to be used by highcharts API
          indicator.chartSettings = {
            chartType: null,
            widgetType: WidgetTypes.Chart,
            axis: ReportAxis.Left,
            sortOrder: 0,
            isFullScreen: false,
            scale: { min: null, max: null },
            showTitleAtBottom: false
          };
          indicator.chartSettings.chartType = (indicator.chartSettings.widgetType === WidgetTypes.Chart) ? ChartTypesEnum.Line : ChartTypesEnum.Bullet;
          indicator.chartSettings.showTitleAtBottom = indicator.chartSettings.widgetType === WidgetTypes.Chart;
         // indicator.uom = getUomByDisplayValue(indicator.displayValue, TowerInputTypesEnum.Tower);
        }

        this.currentTower.indicators = towerIndicators;
      }
    }
  }
  @Mutation
  public setValidationsWidgetData(response: IValidationsWidgetDataResponse): void {
    if (response) {
      if (response.towerKey === this.currentTower.key) {
        // Match response to current tower validations and map to front end interface
        this.currentTower.validations = this.currentTower.validations.map((validation : any) => {
          const validationData = response.inputs.find((item : any) => item.validationKey === validation.validationKey);
          return new TowerValidation(Object.assign(validation, validationData));
        });

        // Build each validation widget and prepare data to be used by highcharts API
        for (const [index, validation] of this.currentTower.validations.entries()) {
          validation.type = TowerInputTypesEnum.Validations;
          validation.chartSettings = {
            chartType: ChartTypesEnum.Line,
            widgetType: WidgetTypes.Chart,
            axis: ReportAxis.Left,
            isFullScreen: false,
            showTitleAtBottom: false,
            sortOrder: index,
            scale: { min: null, max: null }
          };
        }
      }
    }
  }
  @Mutation
  public setTimeRange(settings: IDashboardTimeRangeSetting): void {
    this.currentTimeRange = settings;
  }
  @Mutation
  public setCurrentTimeSelections(selections: Array<UnixEpochTime>): void {
    this.currentTimeSelections = selections;
  }
  @Mutation
  public setCurrentDataSampleSize(size: number): void {
    this.currentDataSampleSize = size;
  }
  @Mutation
  public clearTowerData():void {
    this.currentTower = null;
  }
  @Mutation
  public updateValidationsWidgetData(propertyToUpdate: IObjectPropertyUpdate): void {
    const index = this.currentTower.validations.findIndex((validation : any) => validation.validationKey === propertyToUpdate.validationKey);
    if (has(this.currentTower.validations[index], propertyToUpdate.path)) {
      let validationCopy = { ...this.currentTower.validations[index] };
      validationCopy = set(validationCopy, propertyToUpdate.path, propertyToUpdate.value);
      this.currentTower.validations.splice(index, 1, validationCopy);
    }
  }
  @Mutation
  public updateStageWidgetData(propertyToUpdate: IObjectPropertyUpdate): void {
    if (propertyToUpdate.equipmentType === TowerEquipmentTypeEnum.None) {
      const indicatorIndex = this.currentStage.input.indicators.findIndex((indicator: any) => indicator.key === propertyToUpdate.indicatorKey);
      const objectToUpdate = cloneDeep(this.currentStage.input.indicators[indicatorIndex]);
      if (objectToUpdate) {
        const updatedObject = set(objectToUpdate, propertyToUpdate.path, propertyToUpdate.value);
        this.currentStage.input.indicators.splice(indicatorIndex, 1, updatedObject);
      }
      return;
    }
    const equipmentIndex = this.currentStage.equipment.findIndex((equipment : any) =>
        (equipment.equipmentKey === propertyToUpdate.equipmentKey &&
        equipment.type === propertyToUpdate.equipmentType)
    );
    const indicatorIndex = this.currentStage.equipment[equipmentIndex].indicators.findIndex((indicator: any) => indicator.key === propertyToUpdate.indicatorKey);
    const objectToUpdate = cloneDeep(this.currentStage.equipment[equipmentIndex].indicators[indicatorIndex]);
    if (objectToUpdate) {
      const updatedObject = set(objectToUpdate, propertyToUpdate.path, propertyToUpdate.value);
      this.currentStage.equipment[equipmentIndex].indicators.splice(indicatorIndex, 1, updatedObject);
    }
  }
  @Mutation
  public setTowerStageFilter(value: TowerStageFilterOptionEnum): void {
    this.tableFilterStorage.setUniqueKey(this.currentTower.key).save(value);
    this.towerStageFilter = value;
  }
  @Mutation
  public setTowerThresholds(response: ITowerThresholdsResponse) {
    if (response) {
      // Remove stages with no equipment
      const stagesWithEquipment = response.stages.filter((stage : any) => stage.equipment.length > 0 || stage.stageInputs?.length > 0);

      // Create thresholds map and save to store
      const towerThresholdsMap = new Map();
      stagesWithEquipment.forEach((stage : any) => {
        if (stage.stageInputs) {
          stage.stageInputs.forEach((indicator: any) => {
            const key = `${stage.stageKey}:${indicator.displayName}`;
            towerThresholdsMap.set(key, indicator.thresholds);
          });
        }
        stage.equipment.forEach((equipment: any) => {
          equipment.performanceIndicators.forEach((indicator: any) => {
            const key = `${stage.stageKey}:${equipment.equipmentKey}:${indicator.displayName}`;
            towerThresholdsMap.set(key, indicator.thresholds);
          });
        });
      });
      this.towerThresholdsMap = towerThresholdsMap;
    }
}
  @Mutation
  public setAlertHistory(response: Array<ITowerHistoricalAlert>) {
    if (response) {
      this.currentAlertHistory = response;
      for (const alert of this.currentAlertHistory) {
        alert.chartSettings = {
          chartType: ChartTypesEnum.Line,
          widgetType: WidgetTypes.Chart,
          axis: ReportAxis.Left,
          sortOrder: 0,
          isFullScreen: true,
          scale: { min: null, max: null },
          showTitleAtBottom: false
        };
        const isStageInput = alert.equipmentType === TowerEquipmentTypeEnum.None;
        alert.data = [];
        alert.type = isStageInput ? TowerInputTypesEnum.TowerStageInput : TowerInputTypesEnum.TowerEquipment;
        alert.uom = getUomByDisplayValue(alert.displayName, alert.type);
        alert.displayValue = alert.displayName;
      }
    }
}

  // Actions
  @Action({ rawError: true })
  public async loadTower(key: string): Promise<void> {
    try {
     // const towerService = await initTowerService();
      const tower = await TowerService.createDefault().getTower(key);
      this.setTower(tower);
    } catch (e) {
      throw e;
    } finally {
      await this.loadTowerThresholds(this.currentTower.key);
    }
  }
  @Action({ rawError: true })
  public async getUomOptions(customerKey:string): Promise<IAvailableMeasurementSystemsViewModel[]> {
    const measurementSystems: IAvailableMeasurementSystemsViewModel[] =
        await CustomerPreferenceService.createDefault().getAvailableMeasurementSystems(customerKey);
    this.setUomOptions(measurementSystems);
    const preferredMeasureSystem: IAvailableMeasurementSystemsViewModel = measurementSystems.filter(option => option.preferredFlag)[0];
    this.setPreferredMeasurementSystem(preferredMeasureSystem.systemKey);
    return measurementSystems;
  }
  @Action({ rawError: true })
  public async loadStageWidgetData(measurementSystemKey?: string): Promise<void> {
    // Prepare data to match the equipment indicator request format
    const equipmentIndicators = (this.currentStage?.equipment ?? [])
      .flatMap((i : any) => (i?.indicators ?? []))
      .map((input: any) => new EquipmentIndicatorRequest(input));
    const stageIndicators = (Object.values(StageInputIndicatorDisplayValuesEnum).map(indicator =>
        new StageInputIndicatorRequest(indicator)));
    const requestInputs = [...equipmentIndicators, ...stageIndicators];

    // Create the payload object
    const payload: IStageWidgetDataRequest = {
      towerKey: this.currentTower.key,
      stageKey: this.currentStage.stageKey,
      range: dateObjectsToDateRange(this.currentTimeRange.range),
      sampleSize: this.currentDataSampleSize,
      inputs: requestInputs,
      measurementSystemKey: measurementSystemKey || this.preferredMeasurementSystem
    };

    // Send the payload to the tower service to retrieve stage widget data from API
    const response = await TowerService.createDefault().getStageWidgetData(payload);
    // Pass a valid non-empty response to setStageWidgetData, then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setStageWidgetData(response);
    }
    return Promise.resolve();
  }
  @Action({ rawError: true })
  public async loadTowerWidgetData(key: string, measurementSystemKey?: string): Promise<void> {
    const towerKey = this.currentTower ? this.currentTower.key : key;
    // Create the payload object
    const payload: ITowerWidgetDataRequest = {
      towerKey,
      range: dateObjectsToDateRange(this.currentTimeRange.range),
      sampleSize: this.currentDataSampleSize,
      measurementSystemKey: measurementSystemKey || this.preferredMeasurementSystem
    };
    // Send the payload to the tower service to retrieve tower widget data from API
    const response = await TowerService.createDefault().getTowerWidgetData(payload);

    // Pass a valid non-empty response to setStageWidgetData, then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setTowerWidgetData(response);
    }
    return Promise.resolve();
  }
  @Action({ rawError: true })
  public async loadTowerValidations(measurementSystemKey?: string): Promise<void> {
    // Prepare the data to fetch tower validations
    const validationInputs = this.currentTower?.validations?.map((i: any) => {
      return { 'validationKey': i.validationKey };
    });

    // Create the payload object
    const payload: IValidationsWidgetDataRequest = {
      towerKey: this.currentTower.key,
      sampleSize: 40,
      range: dateObjectsToDateRange(this.currentTimeRange?.range),
      inputs: validationInputs,
      measurementSystemKey: measurementSystemKey || this.preferredMeasurementSystem
    };

    // Send the payload to the tower service to retrieve tower validation data from API
    //const towerService = await initTowerService();
    const response = await TowerService.createDefault().getValidationWidgetData(payload);

    // Pass a valid non-empty response then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setValidationsWidgetData(response);
    }
    return Promise.resolve();
  }
  @Action({ rawError: true })
  public async loadTowerThresholds(key: string): Promise<void> {
    try {
      const response = await TowerService.createDefault().getTowerThresholds(key);
      this.setTowerThresholds(response);
    } catch (e) {
      throw e;
    } finally {}
  }
  @Action({ rawError: true })
  public async loadAlertHistory(request: ITowerAlertHistoryRequest): Promise<void> {
    try {
      const response = await TowerService.createDefault().getAlertHistory(request);
      this.setAlertHistory(response);
    } catch (e) {
      throw e;
    } finally {
    }
  }
  @Action({ rawError: true })
  public async loadHistoricalAlertData(alert: any): Promise<void> {
    if (alert) {
      const date = new Date(alert.alertTimestamp);
      const fromDate = asServerDateTime(date.getTime() - 43200000);
      const toDate = asServerDateTime(date.getTime() + 43200000);
      const type = alert.equipmentType === TowerEquipmentTypeEnum.None
          ? TowerInputTypesEnum.TowerStageInput
          : TowerInputTypesEnum.TowerEquipment;
      const payload: IStageWidgetDataRequest = {
        towerKey: alert.towerKey,
        stageKey: alert.stageKey,
        range: {
          fromDate,
          toDate
        },
        sampleSize: 100,
        inputs: [
          {
            equipmentKey: alert.equipmentKey,
            type,
            equipmentType: alert.equipmentType,
            displayValue: alert.displayName,
            equipmentName: alert.equipmentName
          }
        ],
        measurementSystemKey: ''
      };
      const response = await TowerService.createDefault().getStageWidgetData(payload);
      if (response) {
        alert.data = response.inputs[0].data;
        return alert;
      }
    }
  }

  @Action({ rawError: true })
  public async saveCustomThreshold(request: ITowerCustomThresholdRequest): Promise<void> {
    try {
      const response = await TowerService.createDefault().storeCustomThreshold(request);
      this.setTowerThresholds(response);
    } catch (e) {
      throw e;
    } finally {
    }
  }
  @Action({ rawError: true })
  public async removeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<void> {
    try {
      //const towerService = await initTowerService();
      const response = await TowerService.createDefault().removeCustomThreshold(request);
      this.setTowerThresholds(response);
    } catch (e) {
      throw e;
    } finally {
    }
  }
}

let module: TowerStore = getModule(TowerStore, store);
export const setAppModule = (newModule: TowerStore) => (module = newModule);
export default module;
