import { AxiosInstance } from 'axios';
import {
  IStageWidgetDataRequest,
  IStageWidgetDataResponse,
  ITower, ITowerHistoricalAlert,
  ITowerCustomThresholdRequest,
  ITowerThresholdsResponse,
  IValidationsWidgetDataRequest,
  IValidationsWidgetDataResponse, ITowerAlertHistoryRequest, ITowerWidgetDataRequest, ITowerWidgetDataResponse
} from '@/view-models';
import ControllerBaseService from './common/controller-base-service';
import { baseHttp } from './common/http';

export default class TowerService extends ControllerBaseService {
  constructor(axios: AxiosInstance) {
    super('api/tower', axios);
  }

  public static createDefault(): TowerService {
    return new TowerService(baseHttp.instance);
  }

  // GET /tower/{towerKey}
  public getTower(key: string): Promise<ITower> {
    return this.httpGet<ITower>(key);
  }
  // POST /tower/towerWidgetData
  public getTowerWidgetData(request: ITowerWidgetDataRequest): Promise<ITowerWidgetDataResponse> {
    return this.httpPost<ITowerWidgetDataResponse>('towerWidgetData', request);
  }
  // POST /tower/stageWidgetData
  public getStageWidgetData(request: IStageWidgetDataRequest): Promise<IStageWidgetDataResponse> {
    return this.httpPost<IStageWidgetDataResponse>('stageWidgetData', request);
  }
  // POST /tower/validationWidgetData
  public getValidationWidgetData(request: IValidationsWidgetDataRequest): Promise<IValidationsWidgetDataResponse> {
    return this.httpPost<IValidationsWidgetDataResponse>('validationWidgetData', request);
  }
  // GET /tower/{towerKey}/getTowerThresholds
  public getTowerThresholds(key: string): Promise<ITowerThresholdsResponse> {
    return this.httpGet<ITowerThresholdsResponse>(`${key}/getTowerThresholds`);
  }
  // POST /tower/storeCustomThreshold
  public storeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<ITowerThresholdsResponse> {
    return this.httpPost<ITowerThresholdsResponse>('storeCustomThreshold', request);
  }
  // DELETE /tower/removeCustomThreshold
  public removeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<ITowerThresholdsResponse> {
    return this.httpDelete<ITowerThresholdsResponse>('removeCustomThreshold', request);
  }
  // GET /tower/{towerKey}
  public getAlertHistory(request: ITowerAlertHistoryRequest): Promise<ITowerHistoricalAlert[]> {
    return this.httpGet<ITowerHistoricalAlert[]>(`${request.key}/alertHistory?activeOnly=${request.activeOnly}&CurrentPage=${request.currentPage}&SortBy=${request.sortBy}&SortDesc=${request.sortDesc}`);
  }
}
