import { DateTimeInput } from "@/view-models";
import moment from 'moment';

export default class HelperMethods {

  /**
   * replace a regex string with another string
   * @param string string to perform the replacement on
   * @param replaceWith string to replace with (Default: ' — ' (space em-dash space))
   * @param replaceWhat RegEx to be replaced (Default: /\|/gi)
   * @returns {string}
   */
  public static replacePipe(text: string, replaceWith: string = ' — ', replaceWhat: RegExp = /\|/gi): string {
    return text.replace(replaceWhat, replaceWith);
  }

  /**
   * String shortening. Allows for ellipsis to be on ends of text or to be shown after a set number of characters.
   * @param text : string
   * @param maxLength : number
   * @param clip: number
   * @param reverse: boolean
   * @param separator: string
   */
  public static shortenText(
    text: string,
    maxLength: number = 15,
    clip: number = 0,
    reverse: boolean = false,
    separator: string = '...'
  ) {
    if (text === '') {
      return text;
    }
    text = text.toString().trim();
    if (text.length <= maxLength) {
      return text;
    }
    if (clip >= maxLength) {
      clip = 0;
    }

    const clippedText: string = reverse ? text.substring(0, clip) : text.substring(text.length - clip, text.length);
    const shortenedText: string = reverse
      ? text.substring(text.length - maxLength + (separator.length + clip), text.length)
      : text.substring(0, maxLength - (separator.length + clip));

    return reverse ? clippedText + separator + shortenedText : shortenedText + separator + clippedText;
  }

  /**
   * Generates a random guid string
   * @returns {string}
   */
  public static newGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  /**
   * Get the number of days in a particular month.
   * @param date: Date
   * @returns numberOfDays: number
   */
  public static getDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  public static isMinusSign(keyCode: number): boolean {
    return (
      keyCode === 109 || // numpad
      keyCode === 189 || // keyboard
      keyCode === 173
    ); // Firefox
  }

  public static isDelLeftOrRight(keyCode: number): boolean {
    return keyCode === 8 || keyCode === 46 || keyCode === 37 || keyCode === 39;
  }

  public static keyCodeToChar(keyCode: number): string {
    if (keyCode >= 96 && keyCode <= 105) {
      // Numpad keys
      return String.fromCharCode(keyCode - 48);
    } else {
      return String.fromCharCode(keyCode);
    }
  }

  public static spaceCase(text: string): string {
    const updatedText = text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
    return updatedText;
  }

  public static isStringEmpty(text: string): boolean {
    return text === null || typeof text === 'undefined' || text.trim() === '';
  }

  public static equalsIgnoreCase(text1: string, text2: string): boolean {
    return text1.toLowerCase() === text2.toLowerCase();
  }
  public static convertDate(input: string): Date {
    return this.isStringEmpty(input) ? new Date(2000,1,1) : new Date(input);
  }

  public static asMoment(date: DateTimeInput) {
    return date == null || date === '' ? null : moment(date);
  }

  public static enumKeys(enumType: any): string[] {
    return Object.keys(enumType)
      .map(k => enumType[k])
      .filter(v => typeof v === 'string');
  }
}
export function valueOr(...variables: any[]) {
  for (const v of variables) {
    if (v != null) {
      return v;
    }
  }
  return null;
}

export function toCamelCase(str: string) {
  return convertToCase(str, (word, index) => {
    const head = index === 0 ? word.charAt(0).toLowerCase() : word.charAt(0).toUpperCase();
    return `${head}${word.slice(1).toLowerCase()}`;
  });
}

export function convertToCase(str: string, converter: ((word: string, index?: number) => string)) {
  return str
    .replace(/(?:[_\-+]+)/g, ' ')
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, converter)
    .replace(/\s+/g, '');
}
