import {
  ChartEventsOptions,
  ChartSelectionContextObject,
  dateFormat,
  Options,
  Point,
  PointEventsOptionsObject,
  PositionObject,
  SeriesOptionsType,
  TitleOptions,
  Tooltip,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';
import {
  ChartTypesEnum,
  ReportAxis,
  TowerInputTypesEnum,
  TowerThresholdRangesEnum,
  TowerUnitOfMeasurementsEnum
} from '@/enums';

import VueI18n from 'vue-i18n';
import { toCamelCase } from '@/shared/string-utils';
import WidgetSeriesColors from '@/shared/widget-series-colors';
import EftTime from '@/shared/eft-time';
import { getSeriesColorByUom, getValueYWithFixedDecimalPoint, isStageType } from './tower-utils';

export default class TowerWidgetBuilder {
  // Fields
  private readonly indicator : any;
  protected vueI18n: VueI18n;
  private readonly vueInstance: any;
  private readonly timeSelections: any;

  public constructor(indicator: any, timeSelections: any, vueI18n: VueI18n, vueInstance?: any) {
    this.indicator = Object.assign({}, indicator);
    this.timeSelections = timeSelections;
    this.vueI18n = vueI18n;
    this.vueInstance = vueInstance;
  }
  // Methods
  public buildWidget() {
    // Reference the highcharts API at https://api.highcharts.com/highcharts/
     const chart: Options = {
       time: {
         timezoneOffset: 0,
         useUTC: false
       },
       chart: {
        animation: false,
        backgroundColor: 'transparent',
        width: null,
        height: this.indicator?.chartSettings.isFullScreen ? null : '185px',
        inverted: this.indicator?.chartSettings.chartType !== ChartTypesEnum.Line,
        reflow: true,
        renderTo: 'container',
        margin: this.indicator?.chartSettings.isFullScreen ? [10, 0, 50, 80] : [10, 10, 15, 10],
        resetZoomButton: {
          theme: {
            display: 'none'
           }
         },
        type: this.indicator.chartSettings.chartType.toLowerCase(),
        zoomType: this.indicator.chartSettings.chartType === ChartTypesEnum.Line ? 'x' : undefined,
        events: this.buildChartEventHandlers
      },
      noData: {
        style: {
          color: 'var(--gray-500)'
        }
      },
      lang: {
        noData: this.vueI18n.t(`chart.noData`).toString()
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: { // general options for all series
        series: {
          lineWidth: 1,
          animation: false,
          cursor: 'pointer',
          marker: {
            enabled: false
          },
          point: {
            events: this.buildPointEventHandlers
          },
          tooltip: {
            xDateFormat: '%b %e, %Y - %l:%M %P'
          }
        }
      },
      // rangeSelector: this.buildRangeSelector,
      series: this.buildSeries, // specific options for the series instance
      title: this.buildTitle,
      tooltip: this.buildToolTip,
      xAxis: this.buildXAxis,
      yAxis: this.buildYAxis
    };
     return chart;
  }
  // Getters
  private get buildSeries(): Array<SeriesOptionsType> {
    // Build performance indicator Line series type
    if (this.indicator.chartSettings.chartType === ChartTypesEnum.Line) {
      const series: SeriesOptionsType = {
        data: [],
        type: this.indicator.chartSettings.chartType.toLocaleLowerCase(),
        yAxis: this.indicator.chartSettings.axis === ReportAxis.Left ? 0 : 1,
        connectNulls: true,
        zoneAxis: 'y',
        zones: []
      };
      if (this.indicator.type === TowerInputTypesEnum.Tower) {
        return this.indicator.inputs.map((input: any) => {
          const index = this.indicator.inputs.indexOf(input);
          const color = WidgetSeriesColors.getColor(index + 4).toString();
          const seriesCopy = Object.assign({}, series);
          series.id = `${this.indicator.key}:${this.indicator.displayValue}`;
          seriesCopy.data = input.data;
          seriesCopy.name = input.dataSetName
              ? this.vueI18n.t(`assets.tower.displayValues.${toCamelCase(input.dataSetName)}`).toString()
              : '';
          seriesCopy.borderColor = color;
          seriesCopy.color = color;
          return seriesCopy;
        });
      }
      if (this.indicator.type === TowerInputTypesEnum.Validations) {
        return this.indicator.inputs.map((input: any) => {
          const index = this.indicator.inputs.indexOf(input);
          const color = WidgetSeriesColors.getColor(index + 4).toString();
          const seriesCopy = Object.assign({}, series);
          series.id = `${this.indicator.validationKey}:${this.indicator.inputs[index].dataSetName}`;
          seriesCopy.data = input.data;
          seriesCopy.name = input.dataSetName
              ? this.vueI18n.t(`assets.tower.displayValues.${toCamelCase(input.dataSetName)}`).toString()
              : '';
          seriesCopy.borderColor = color;
          seriesCopy.color = color;
          return seriesCopy;
        });
      }

      if (isStageType(this.indicator.type)) {
        const seriesColor = getSeriesColorByUom(this.indicator.unitOfMeasurement).toString();
        series.id = this.indicator.type === TowerInputTypesEnum.TowerEquipment
            ? `${this.indicator.equipmentKey}:${this.indicator.displayValue}`
            : this.indicator.displayValue;
        series.data = this.indicator.data;
        series.name = this.indicator.displayValue
            ? this.vueI18n.t(`assets.tower.displayValues.${toCamelCase(this.indicator.displayValue)}`).toString()
            : '';
        series.borderColor = seriesColor;
        series.color = seriesColor;
        if (this.indicator.thresholds?.length > 0) {
          for (const threshold of this.indicator.thresholds) {
            switch (threshold.alertType) {
              case TowerThresholdRangesEnum.OutsideRange:
                series?.zones?.push(
                  { value: threshold.minValue, color: threshold.color },
                  { value: threshold.maxValue, color: seriesColor },
                  { value: undefined, color: threshold.color });
                break;
              case TowerThresholdRangesEnum.InsideRange:
                series?.zones?.push(
                  { value: threshold.minValue, color: seriesColor },
                  { value: threshold.maxValue, color: threshold.color },
                  { value: undefined, color: seriesColor });
                break;
              case TowerThresholdRangesEnum.LessThanValue:
                series?.zones?.push(
                  { value: undefined, color: threshold.color },
                  { value: threshold.maxValue, color: seriesColor });
                break;
              case TowerThresholdRangesEnum.GreaterThanValue:
                series?.zones?.push(
                  { value: threshold.maxValue, color: seriesColor },
                  { value: undefined, color: threshold.color });
                break;
            }
          }
        }
        return [series];
      }
    }
    return [];
  }
  private get buildTitle(): TitleOptions {
    if (this.indicator.chartSettings.chartType === ChartTypesEnum.Line) {
      return {
        text: undefined
      };
    } return {};
  }

  private get buildToolTip(): TooltipOptions {
    const dateMarkup: string = `<div title="{point.key}" class="chart-widget-view__date-time" style="color:white">{point.key}</div>`;
    let thresholdsMarkup: string = '';
    const self = this;
    if (this.indicator?.thresholds?.length > 0) {
        this.indicator.thresholds.forEach((threshold : any) => {
          const thresholdName = threshold.thresholdName !== '' ? this.vueI18n.t(`dashboard.thresholds.threshold`) : threshold.thresholdName;
          if (threshold.thresholdType === 'Range' && (threshold.maxValue !== null && threshold.maxValue !== '' && threshold.minValue !== null && threshold.minValue !== '')) {
            thresholdsMarkup += `<div class="ml-3 mb-1"><span class="mr-1" style="color:${threshold.color}">\u25CF</span>${thresholdName} ${threshold.minValue} - ${(threshold.maxValue) ?? 100} ${this.indicator.unitOfMeasurement}</div>`;
          } else if (threshold.maxValue !== null && threshold.maxValue !== '') {
            thresholdsMarkup += `<div class="ml-3 mb-1"><span class="mr-1" style="color:${threshold.color}">\u25CF</span>${thresholdName} ${threshold.maxValue} ${this.indicator.unitOfMeasurement}</div>`;
          }
      });
    } else {
      thresholdsMarkup = '';
    }

      return {
      animation: false,
      backgroundColor: '#393C4B',
      borderColor: '#393C4B',
      borderRadius: 6,
      enabled: (!this.indicator.chartSettings.isFullScreen),
      outside: true,
      shared: true,
      useHTML: true,
      headerFormat: dateMarkup,
      pointFormatter() {
        return `<div><span class="mr-1" style="color:${this.color}">\u25CF</span>${this.series.name}
              <b class="ml-2">${getValueYWithFixedDecimalPoint(this.y!)}</b>${self.unitsOfMeasureString}</div>`;
      },
      footerFormat: thresholdsMarkup,
      followPointer: true,
      style: {
        color: '#FFFFFF',
        whiteSpace: 'pre-wrap'
      },
      xDateFormat: '%b %e, %Y - %l:%M %P',
      positioner(this: Tooltip, labelWidth, labelHeight): PositionObject {
        const chartPosition = this.chart.pointer.getChartPosition();
        return (self.indicator.chartSettings.isFullScreen
            ? {
              x: Math.floor(chartPosition.left - (labelWidth / 20)),
              y: labelHeight > 70
                  ? Math.floor(chartPosition.top - labelHeight / 4)
                  : Math.floor(chartPosition.top - labelHeight / 2)
            }
            : {
              x: Math.floor(chartPosition.left + ((this.chart.chartWidth - labelWidth * 2) / 10)),
              y: Math.max(chartPosition.top - (labelHeight + 55), 15)
            });
      }
    };
}
  private get buildXAxis(): (XAxisOptions | Array<XAxisOptions>) {
    if (this.indicator.chartSettings.chartType === ChartTypesEnum.Line) {
      // Using time selections to display vertical white line
      const plotLines = this.timeSelections.map((i : any) => {
        return {
          color: '#DADADA',
          width: 1,
          value: i,
          dashStyle: 'solid',
          label: {
            text: 'Selection',
            verticalAlign: 'top',
            textAlign: 'right',
            style: {
              color: 'white',
              fontWeight: 'bold',
              fontSize: '10px'
            },
            y: -20
          }
        };
      });
      return {
        tickInterval: 0,
        gridLineWidth: 0,
        gridLineColor: 'transparent',
        tickWidth: 0,
        dateTimeLabelFormats: {
          day: '%b %e',
          hour: '%l %P',
          minute: '%l:%M %P'
        },
        labels: {
          enabled: this.indicator?.chartSettings.isFullScreen,
          style: {
            color: '#DADADA'
          },
          y: 30
        },
        plotLines,
        type: 'datetime',
        visible: true,
        lineWidth: 0
      };
    } return [];
  }
  private get buildYAxis(): Array<YAxisOptions> {
    if (this.indicator.chartSettings.chartType === ChartTypesEnum.Line) {
      const plotLines = [];
      if (this.indicator.thresholds?.length > 0) {
        for (const threshold of this.indicator.thresholds) {
          switch (threshold.thresholdType) {
            case 'Value':
              plotLines.push({
                width: 1,
                value: threshold.maxValue,
                color: threshold.color,
                className: 'threshold-lines',
                borderWidth: 1,
                zIndex: 11,
                dashStyle: 'dash'
              });
              break;
            case 'Range':
              plotLines.push({
                width: 1,
                value: threshold.minValue,
                color: threshold.color,
                className: 'threshold-lines',
                borderWidth: 1,
                zIndex: 11,
                dashStyle: 'dash'
              }, {
                width: 1,
                value: threshold.maxValue,
                color: threshold.color,
                className: 'threshold-lines',
                borderWidth: 1,
                zIndex: 11,
                dashStyle: 'dash'
              });
              break;
          }
        }
      }
      let titleText = null;
      if (this.indicator?.chartSettings.isFullScreen) {
        titleText = (isStageType(this.indicator.type))
            ? 'Units ' + '( ' + this.indicator.unitOfMeasurement + ' )'
            : 'Values';
      }
      const yAxis: YAxisOptions | any = {
        gridLineColor: 'rgba(218, 218, 218, 0.2)',
        gridLineDashStyle: 'LongDash',
        gridLineWidth: 1,
        max: this.indicator.chartSettings.scale.max,
        min: this.indicator.chartSettings.scale.min,
        tickPixelInterval: 50,
        labels: {
          value: 0,
          enabled: this.indicator?.chartSettings.isFullScreen,
          style: {
            color: '#DADADA'
          },
          formatter() : any {
              return `${getValueYWithFixedDecimalPoint(this.value, true)}`;
          }
        },
        title: {
          margin: 10,
          style: {
            color: '#DADADA',
            fontSize: '13px'
          },
          text: titleText
        },
        plotLines
      };
      return [yAxis];
    } return [];
  }

  public get unitsOfMeasureString(): string {
    let unitsOfMeasureString = '';
    // Validations comes from backend the rest are mapped via front end
    if (this.indicator.type === TowerInputTypesEnum.Validations) {
      const uom = this.indicator?.unitOfMeasurement
          ? this.vueI18n.t(`assets.tower.uoms.${toCamelCase(this.indicator.unitOfMeasurement)}`).toString()
          : '';
      unitsOfMeasureString = (this.indicator.unitOfMeasurement === TowerUnitOfMeasurementsEnum.none)
          ? TowerUnitOfMeasurementsEnum.empty
          : uom;
    } else {
      unitsOfMeasureString = this.indicator.unitOfMeasurement;
    }

    return `<span class="font-weight-light ml-1">${unitsOfMeasureString}</span>`;
  }

  // EVENT HANDLERS
  private get buildChartEventHandlers(): ChartEventsOptions {
    const vueInstance = this.vueInstance;
    return {
      selection(e: ChartSelectionContextObject) {
        // @ts-ignore // resetSelection is being used by highcharts API but is not currently provided via typescript
        if (!e.resetSelection) {
          // The Selection event fires when resetting zoom so this is needed to maintain correct visibility of the icon
          vueInstance.toggleZoomResetIcon();
        }
        return true;
      }
    };
  }
  private get buildPointEventHandlers(): PointEventsOptionsObject {
    const vueInstance = this.vueInstance;
    const el = this;
    return {
      mouseOver(this: Point) {
          if (el.indicator.chartSettings.isFullScreen) {
            const thresholds = (el.indicator.thresholds ?? []);
            const offset = new Date().getTimezoneOffset() * 1000 * EftTime.minute;
            const time = dateFormat('%b %e, %Y - %l:%M %P', this.x - offset);
            vueInstance.buildChartLegend(this, time, thresholds);
          }
      }
    };
  }
}
