


































// Libraries
import { Component, Prop } from 'vue-property-decorator';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
// import RelativeTime from '@/components/shared/RelativeTime.vue';
import TowerStageTableRow from '@/components/assets/tower/TowerStageTableRow.vue';
import { TranslateResult } from 'vue-i18n';
import { ITowerStage } from '@/view-models';

@Component({
  name: 'tower-stage-card',
  components: {
    TowerStageTableRow,
    // RelativeTime
  }
})
export default class TowerStageCard extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public stage!: ITowerStage | any;
  @Prop({ required: true })
  public index!: number;
  // Properties
  // VUEX
  // Fields
  // Getters
  public get alertsStatus(): string | TranslateResult {
    return this.hasAlerts
        ? this.$t('assets.tower.stages.alertsStatusWarning', { num: this.stage.alerts.length }).toString()
        : this.$t('assets.tower.stages.alertsStatusOk');
  }
  public get equipmentStatus(): string | TranslateResult {
    return this.hasEquipment
        ? this.$t('assets.tower.stages.hasEquipment', { num: this.stage.equipment.length }).toString()
        : this.$t('assets.tower.stages.hasNoEquipment');
  }
  public get hasAlerts(): boolean {
    return (this.stage?.alerts?.length ?? 0) > 0;
  }
  public get hasEquipment(): boolean {
    return (this.stage?.equipment?.length ?? 0) > 0;
  }
  public get stageName(): string | TranslateResult {
    return this.$t('assets.tower.stages.nameTitle', { num: this.stage.order });
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted() {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
