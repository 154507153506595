export enum WidgetRangeType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last24Hours = 'Last24Hours',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last90Days = 'Last90Days',
  Last180Days = 'Last180Days',
  MonthToDate = 'MonthToDate',
  YearToDate = 'YearToDate',
  SpecificYear = 'SpecificYear'
}

export enum DashboardTimeRangesEnum {
  OneHour = '1Hour',
  EightHours = '8Hours',
  OneDay = '1day',
  OneWeek = '1week',
  OneMonth = '1month',
  Custom = 'custom'
}

export enum WidgetTypes {
  Chart = 'Chart',
  Status = 'Status',
  Gauge = 'Gauge',
  BulletGauge = 'BulletGauge'
}

export enum ChartTypesEnum {
  Area = 'Area',
  AreaSpline = 'AreaSpline',
  Bullet = 'Bullet',
  Line = 'Line',
  Spline = 'Spline',
  Column3d = 'Column3d',
  Column = 'Column',
  Unknown = 'Unknown'
}

export enum WidgetSizes {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large'
}

export enum WidgetActions {
  Fullscreen = 'fullscreen'
}

export enum WidgetInputValues {
  Consumption = 'Consumption',
  Cost = 'Cost',
  Minimum = 'Minimum',
  Maximum = 'Maximum',
  Average = 'Average',
  LastReadValue = 'LastReadValue'
}

export enum SkipDirectionsEnum {
  Backwards = 'Backwards',
  Forward = 'Forward'
}

export enum TimeTypeEnum {
  Days = 'Days',
  Hours = 'Hours',
  Minutes = 'Minutes',
  Seconds = 'Seconds',
  Milliseconds = 'Milliseconds'
}

export enum MeasurementSystemsEnum {
  DefaultUnits = 'DefaultUnits',
  ImperialUnits = 'ImperialUnits',
  SIUnits = 'SIUnits',
  MetricUnits = 'MetricUnits',
  PTTGCUnits = 'PTTGCUnits'
}

export enum InputDateRangeEnum {
  Start = 'Start',
  End = 'End'
}
