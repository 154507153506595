
  import HelperMethods from '@/shared/helper-methods';
import { BvToastOptions } from 'bootstrap-vue/src/components/toast';
import { Vue, Component } from 'vue-property-decorator';

  @Component({
    name: 'BaseComponent'
  })

  export default class BaseComponent extends Vue {

    public cid: string = HelperMethods.newGuid();

    public elementId(entityType: string, propertyOrActionOrInputName: string, entityKey?: string): string {
      let componentId: string = `${entityType}-${propertyOrActionOrInputName}`;
      if (!this.isNullOrWhiteSpace(entityKey ? entityKey : '')) {
        componentId = `${componentId}-${entityKey}`;
      }
      return componentId;
    }
    public isNullOrWhiteSpace(input: string): boolean {
      if (typeof input === 'undefined' || input == null) {
        return true;
      }
      return input.replace(/\s/g, '').length < 1;
    }

    protected toastMessage(message: string, title?: string, variant?: string, options?: BvToastOptions): void {
    this.$bvToast.toast(message, {
      title,
      variant: variant ?? 'info',
      toaster: 'b-toaster-top-right',
      ...options
    });
  }

    public tOr(tag: string, otherwise: string = '', values: any | { [key: string]: any } = null) {
      return tag && this.$te(tag) ? this.$t(tag, values) : otherwise;
    }
  }
