import {
  Module,
  VuexModule,
  getModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
import { IErrorPayload, IErrorStore } from '@/store/types/error';
import ErrorParser, { emitError } from '@/utils/error-parser';

@Module({
  namespaced: true,
  name: 'errorStore',
  store,
  dynamic: true,
})

export class ErrorStore extends VuexModule implements IErrorStore {
  // State
  public error: any = null; // Error message
  public handleError: boolean = false; // Handle error within child app
  public routeHomeAfterError: boolean = false; // Force user to return to home screen
  public showModal: boolean = false; // Show child app error modal

  // Getters
  // Mutations
  @Mutation
  public clearError(): void {
    this.error = null;
    this.handleError = false;
    this.routeHomeAfterError = false;
    this.showModal = false;
  }
  @Mutation
  public setError(payload: IErrorPayload): void {
    // Checking if payload has an error instance
    if (payload.error) {
      // Check if error has a response
      if (payload.error.response) {
        const statusCode = payload.error.response.status;
        if (statusCode >= 400 && statusCode < 500) {
          this.error = new ErrorParser(payload).asClientError;
        } else if (statusCode >= 500) {
          this.error = new ErrorParser(payload).asServiceError;
        } else {
          this.error = `${payload.errorString}\nUnknown Error: ${statusCode}`;
        }
      } else if (payload.error.message) {
        this.error = payload.errorString + payload.error.message;
      } else if (payload.error.error_description) {
        this.error = `${payload.errorString}\n${ payload.error.error_description}`;
      } else { // This is handling non http error codes
        this.error = payload.errorString;
      }
    } else { // Setting an error with error string passed if error instance is not present
      this.error = payload.errorString;
    }
    this.handleError = payload.handleError;
    this.routeHomeAfterError = payload.routeHomeAfterError;
    emitError(this);
  }

  // Actions
  @Action({ rawError: true })
  public async tryExecute(payload: any): Promise<void> {
    try {
      if (payload.action) {
        await payload.action();
      }
    } catch (err) {
      const errorString = `${payload?.errorMsg}\n`;
      this.setError({
        error: err,
        errorString,
        handleError: true,
        routeHomeAfterError: payload?.routeHomeAfterError ?? false
      });
    }
  }
}

let module: ErrorStore = getModule(ErrorStore, store);
export const setErrorModule = (newModule: ErrorStore) => (module = newModule);
export default module;
