



















// Libraries
import { Component, Prop } from 'vue-property-decorator';
// View Models
// Components
import BaseComponent from '@/components/shared/BaseComponent.vue';
import { WcagColors } from '@/enums/wcag-colors';
// Services
// Store

@Component({
  name: 'color-picker'
})
export default class ColorPicker extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: false, default: (): Array<WcagColors> => [WcagColors.Green, WcagColors.Yellow, WcagColors.Red] })
  public definedColors!: Array<WcagColors>;

  @Prop({ required: true })
  public value!: WcagColors;

  @Prop({ required: false, default: true })
  public showLabel!: boolean;

  @Prop({ default: null })
  public id!: string;

  @Prop({ default: 'colorPicker' })
  public type!: string;

  @Prop({ default: false })
  public disabled!: boolean;

  private _uid: string = '';
  private wcagColors: any = WcagColors;

  // VUEX
  // Properties
  // Fields
  // Getters
  private get colors(): WcagColors {
    const o = {} as WcagColors | any;
    this.definedColors.map((c: WcagColors) => {
      const key = Object.keys(WcagColors).find((k: any) => this.wcagColors[k] === c);
      if(key) {
      o[key] = c;
      }
    });
    return o;
  }

  private get compId(): string {
    return this.id + '-' + this._uid;
  }

  private get styleColor(): string {
    const value: string = this.value;
    return value;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // public mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public selectColor(selection: string): void {
    this.$emit('input', selection);
    const colorPickerEl = (this.$refs.colorPicker as any);
    if (colorPickerEl.hide) {
      colorPickerEl.hide();
    }
  }
  // Watchers
  // Emitters
}
